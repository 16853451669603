import './TextInputList.css'



import TextInput from './TextInput'

import { formatHint } from '../Methods'


const TextInputList = ( { id, quantity, text, onchange } ) => {



	const renderList = () => {
		let arr =[] 

		for ( let x=0; x< parseInt(quantity ); x++ ){
			arr.push(1)
		}
		return(
			arr.map( (ele, index) => {
				const name = `${id}_${index+1}`			
				return(
					<TextInput
						key={index}
						name = { name }
						hint={ formatHint(name) }
						text={ text === undefined ? '' : text[name] }
						onchange={ event => onchange(event) }
						digit={ id === 'eta_festeggiati' ? true : false }
					/>
				)
			} )
		)
	}



	return(
		<div className='text-list-container'>
			{
				renderList()
			}
		</div>
	)
}


export default TextInputList
