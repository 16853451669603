import './App.css';

import { useEffect, useRef } from 'react'
import Login from './components/pages/Login'
import Prenotazioni from './components/pages/Prenotazioni'
import BookingsOverview from './components/pages/BookingsOverview'
import CakeOverview from './components/pages/CakeOverview'
import FestePrivate from './components/pages/FestePrivate'
import EventiOverview  from './components/pages/EventiOverview'
import Navbar from './components/sections/Navbar'

import Calendar from './components/pages/Calendar'



import { Route, Switch, Redirect } from 'react-router-dom'
import  { UserContextProvider } from './components/context/UserContext'
import  { EventsContextProvider } from './components/context/EventsContext'
import AuthCheck from './components/views/AuthCheck';





const App = () => {


	
	const pageRef = useRef()


        useEffect( () => {
		const uA = navigator.userAgent
		if( uA.includes("iPhone") ||  uA.includes("Android") ){
			const head = document.querySelector("head");
			const head_html = head.innerHTML;
			const no_zoom = "<meta name='viewport' content='user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, height=device-height, target-densitydpi=device-dpi' />"
			const new_head = head_html + no_zoom;
			head.innerHTML = new_head;
		}
        },[] )



  return (
    <div className="App">
	    <UserContextProvider>

		    <AuthCheck/>


		    <Navbar
			    pageRef={pageRef}
		    />


		    <div 
			    className='page'
			    ref={pageRef}
		    >

		    <Switch>
			    <Route path='/birthday' exact >
				    <Prenotazioni/> 
			    </Route>

			    <Route path='/' exact >
			    	<EventsContextProvider>
				    <Calendar/> 
			    	</EventsContextProvider>
			    </Route>


			    <Route path='/evento' exact >
				    <FestePrivate/> 
			    </Route>


			    <Route path='/login' exact >
				    <Login/>
			    </Route>
			    <Route path='/overview' exact >
				    <BookingsOverview/>
			    </Route>
			    <Route path='/eventi_overview' exact >
				    <EventiOverview/>
			    </Route>
			    <Route path='/cake_overview' exact >
				    <CakeOverview/>
			    </Route>
			    <Route path='*' >
				    <Redirect to='/' />
			    </Route>

		    </Switch>

		    </div>
	    </UserContextProvider>
    </div>
  );
}

export default App;
