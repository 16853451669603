import { useEffect, useState, useRef } from 'react'
import CalendarContainer from '../sections/CalendarContainer'
import { isDictUndef }  from '../Methods'
import { BASE_WS } from '../Constants'


const Calendar = () =>{

	const [ reload, setReload ] = useState(false)


	const wsRef = useRef(null)
	const timeoutRef = useRef(null)
	const timeout1Ref = useRef(null)
	const intervalRef = useRef( null )

	const createWebSocket = () => {
		const ws = new WebSocket(`${ BASE_WS() }/update_events/`)
		ws.onmessage = ( msg ) =>{
			if ( msg.data !== null){ 
				const data =  JSON.parse(msg.data) 
				if ( !isDictUndef( data ) ){
					Object.keys( data ).forEach( ( key ) =>{
						if (key === 'action'){
							if (data.action === 'reload'){
								setReload(true)
								timeoutRef.current = setTimeout( () => setReload(false), 100	)
							}
						}
					} )
				}
			}
		}
		ws.onerror = function(e) {

			timeout1Ref.current = setTimeout( () => createWebSocket(), 5000 )
		}

		wsRef.current = ws



	}

	useEffect( () =>{

		createWebSocket()
		intervalRef.current = setInterval(() => {
			if ( wsRef.current == null || wsRef.current.readyState === WebSocket.CLOSED ){
				clearTimeout(timeoutRef.current)
				clearTimeout(timeout1Ref.current)
				createWebSocket()
			}
		}, 60000)


		return () =>{
			clearTimeout(timeoutRef.current)
			clearTimeout(timeout1Ref.current)
			clearInterval(intervalRef.current)
			wsRef.current?.close()
		}
	},[] )	


	return (
		<div className='calendar-page'>
		
			<CalendarContainer reload={reload}>
			</CalendarContainer>
		</div>
	)
}



export default Calendar
