import React, { createContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export const UserContext = createContext()




const initialContext = () =>{
	const localUser = sessionStorage.getItem('user')
	if (
		localUser !== undefined &&
		localUser !== null &&
		localUser !== 'undefined'
	){
		return JSON.parse(localUser)
	}
	else
		return {}
}


export const UserContextProvider = ( props ) => {
	
	const [ user, setUser ] = useState(
		() => initialContext() 
		)

	const history = useHistory()

	useEffect( () =>{
		sessionStorage.setItem('user', JSON.stringify(user))
		if (user === {}){
			history.push('/login')
		}
	},[user, history] )
	

	return(
		<UserContext.Provider value={[user, setUser]}>
		{
			props.children
		}
		</UserContext.Provider>
	)
}
