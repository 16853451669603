import './Cella.css'

import { useMemo } from 'react'





const Cella = ({ children, content, style, notPrint })=>{



	const printClass = useMemo( () =>{

		if ( notPrint ){
			return 'not-print'
		}

		else return ''

	},  [ notPrint ] )



	if ( children )
		return (
			<td 	style={style}
				className={`cella cella-children ${printClass} `}>
				{
					children
				}
			</td>
		)

	return(
		<td 	style={ style }
			className={`cella ${printClass}`}>

			<span>
			{content}
			</span>
		</td>
	)
}



export default Cella
