import { useState, useEffect, useCallback } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import FormMask from '../sections/FormMask'
import { useForm, usePost, useGet} from '../Hooks'
import { Redirect } from 'react-router-dom'
import Button from '../views/Button'
import AuthCheck from '../views/AuthCheck'
import NewBookingModalContainer from '../sections/NewBookingModalContainer'
import Loader from '../sections/Loader'
import {
	BASE_URL,
	initialEventoData,
	initialCakeData,

} from '../Constants'

import './Prenotazioni.css'
import  ConfirmModal  from '../sections/ConfirmModal'


import { 
	showError,
	checkUndefArr,
	fixTypesQuery,
	checkNoUser,
	isDictUndef,

} from '../Methods'



const createEventUrl = BASE_URL()+'/create_booking/event/'
const getFormDataUrl = BASE_URL()+'/get_form_data_party/'
const updateBookingUrl = BASE_URL()+'/update_booking/'
const getEventFormDataUrl = BASE_URL()+'/get_event_form_detail/'
const notifyUpdateUrl = BASE_URL()+'/notify_update/'

const FestePrivate = () => {

	const location = useLocation()
	const history = useHistory()

	const [ initialSelectEventoData, setInitialSelectEventoData ] = useState()
	const [ initialSelectCakeData, setInitialSelectCakeData ] = useState()
	const [ eventId, setEventId ] = useState(0)
	const [ showDetailsModal, setShowDetailsModal ] = useState( false )
	const [ showEmailModal, setShowEmailModal] = useState( false )
	const [ fetchPostData, setFetchPostData ] = useState({})
	const [ fetchGetData, setFetchGetData ] = useState( { url:getFormDataUrl }  )
	const [ Pdata, Ploading, Perror ] = usePost( fetchPostData  )
	const [ Gdata, Gloading, Gerror ] = useGet( fetchGetData )

	const [ showCakeForm, setShowCakeForm ] = useState( false )

	const [ 
		formValues,
		setFormValues,
		setFormValuesByEvent,
	] = useForm( { evento:{}, cake:{} } ) 


	const notifyUpdateHandler = useCallback( () =>{
		setFetchPostData({url:null, query:null})
		setFetchPostData( { url:notifyUpdateUrl, query:{ id:formValues.evento.id } } )
	}, [ setFetchPostData, formValues ] )


	const showModalHandler = useCallback( ( action ) =>{
		if (action === 'show' )
			setShowDetailsModal(true)
		else if ( action === 'hide' )
			setShowDetailsModal(false)
	}, [ setShowDetailsModal ])

	const sendBookingRequest = useCallback(  () =>{

		setFetchPostData({
			url:createEventUrl,
			query:null,
		})
		let queryData = {}
		let localEventoValues = sessionStorage.getItem('evento')
		let localCakeValues = sessionStorage.getItem('cake')
		let strValuesList = [ localEventoValues, localCakeValues ]
		if ( checkUndefArr( strValuesList ) ){
			for ( let  strval of strValuesList ){
				const dict = fixTypesQuery(strval)
				if ( dict.dolce ){
					queryData['evento'] = dict
				}
				else if ( dict.cialda ){
					queryData['cake'] = dict
				}
			}
			setFetchPostData({
				url:createEventUrl,
				query:queryData,
			})
		}
	}, [  setFetchPostData ]   )


	const updateBookingRequest = useCallback( () =>{
		setFetchPostData({
			url:null,
			query:null
		})

		const PerrorLabels = document.querySelectorAll('.error-label')
		for (let label of PerrorLabels){
			label.remove()
		}


		let queryData = {}
		let localEventoValues = sessionStorage.getItem('evento')
		let localCakeValues = sessionStorage.getItem('cake')
		let strValuesList = [ localEventoValues, localCakeValues ]
		if ( checkUndefArr( strValuesList ) ){
			for ( let  strval of strValuesList ){
				const dict = fixTypesQuery(strval)
				if ( dict.dolce ){
					queryData['evento'] = dict
				}
				else if ( dict.cialda ){
					queryData['cake'] = dict
				}
			}
			setFetchPostData({
				url:updateBookingUrl,
				query:formValues,
			})
		}

	},[setFetchPostData, formValues] )

	const clearForm = useCallback( () =>{
		setFetchGetData( { url:null} )
		sessionStorage.removeItem('evento')
		sessionStorage.removeItem('cake')
		setFormValues({ evento:{}, cake:{} })
		setFetchGetData({ url:getFormDataUrl} )
	}, [ setFormValues, setFetchGetData ] )



	useEffect( () =>{
		let resetDict ={ evento:{}, cake:{} } 
		if (  location.state ){
			const localBookingData = sessionStorage.getItem('evento')
			const localCakeData  = sessionStorage.getItem('cake')
			if ( localBookingData !== null){
				const bookingDataDict = JSON.parse( localBookingData)
				resetDict.evento= bookingDataDict
			}
			if ( localCakeData !== null){
				const cakeDataDict = JSON.parse( localCakeData)
				resetDict.cake= cakeDataDict 
			}
			if ( location.state.date )
				resetDict.evento['data'] = location.state.date
		}
		setFormValues( resetDict )
		return () =>{
				sessionStorage.evento='{}'
				sessionStorage.cake='{}'
		}

	},[] )


	useEffect( () =>{
		if ( location.state ){
			if ( location.state.listValues ){
				setFormValues( old => { return { ...old, ...location.state.listValues } } )
			}
		}
	}, [ location, setFormValues ] )


	useEffect( () => {
		if( Gdata ){
			if ( Gdata.farcitura ){
				setInitialSelectCakeData({
							cialda:[
								{text:'no',value:false},
								{text:'si',value:true}
							],

							bagna_alcolica:[
								{text:'no',value:false},
								{text:'si',value:true}
							],
							tipo:Gdata.tipo_dolce,
							farcitura:Gdata.farcitura,
							copertura:Gdata.coperture,
							base:Gdata.basi,
							forma:Gdata.forme,
							})


				setInitialSelectEventoData({
						bambini:[{text:'no',value:false},
							{text:'si', value:true}],
						dolce:[ {text:'no',value:false}, 
							{text:'si', value:true}],
						tipo_pagamento:[
							{ text:'contanti', value:'CT'},
							{text:'bonifico', value:'BN'},
							{text:'satispay',value:'SP'}
							],
						locale:Gdata.locali,
						tipo_evento:Gdata.eventi,
						})


			}
		}

	}, [ 	Gdata,
		Gloading,
		Gerror,
		setInitialSelectEventoData,
		setInitialSelectCakeData
	])

	useEffect( ()=> {
		if ( formValues.evento.dolce === 'true' || formValues.evento.dolce === true )
			setShowCakeForm(true)
		else 
			setShowCakeForm(false)
	}, [formValues] )


	
	useEffect( () =>{
		if( Perror !== undefined && Perror !== null && Perror.detail === undefined ){
			const ids = Object.keys( Perror )
			for ( let id of ids ){
				showError(id, Perror[id] )
			}
		}
		else if (
			Pdata !== undefined && 
			Pdata !== null ){
			if ( ! fetchPostData.url.includes( 'get_event_form_detail' ) ){
				if ( Pdata.id !== undefined ){
					setEventId(Pdata.id)
					setShowDetailsModal(true)
					clearForm()
				}
				else if ( Pdata.update ){
					setShowEmailModal( true )
				}
			}
				else{
					setFormValues( Pdata )
				}
		}



	}, [ 	Pdata,
		Ploading,
		Perror,
		setFormValues,
		setEventId,
		setShowDetailsModal,
		setShowEmailModal,
	] )



	const renderFormMask = useCallback( () =>{
		if ( Gdata &&  ! isDictUndef( Gdata.locali ) ){
			return (
				<>
				<FormMask
					title='Dettagli cliente'
					initialFormData={ initialEventoData }
					selects={ initialSelectEventoData }
					formValues = { formValues.evento }
					setFormValues = { setFormValues }
					setFormValuesByEvent = { setFormValuesByEvent }
					group = 'evento'
					show={ true }
				/>
				<FormMask
					title='Dettagli dolce'
					selects ={ initialSelectCakeData } 
					initialFormData={ initialCakeData }
					formValues= { formValues.cake }
					setFormValues = { setFormValues }
					setFormValuesByEvent = { setFormValuesByEvent }
					group = 'cake'
					show = { showCakeForm }
				/>
				</>
			)
		}
	}, [ 
		initialEventoData,
		Gdata,
		initialSelectEventoData,
		formValues,
		setFormValuesByEvent,
		setFormValues,
		showCakeForm,
		initialSelectCakeData,
		initialCakeData,
	] )

	useEffect( () =>{
		if ( location.state ){
			if ( location.state.type){
				setFetchPostData({ 
						url:`${getEventFormDataUrl}${ location.state.type }/`,
						query:{ id:location.state.id }
				})
			}
		}
	}, [ location, setFormValues, setFetchPostData ] )


	const renderButtons = useCallback( () =>{
		if ( location.state ){
			if ( ! location.state.date )
			return(
				<Button 
					text='Salva'
					onclick={ () => {
						updateBookingRequest()	
						} }
					color='primary'
				/>
			)
		}
			return(
				<>
				<Button 
					text='Continua'
					onclick={ () => {sendBookingRequest()}	}
					color='primary'
				/>
				<Button 
					text='Svuota campi'
					onclick={ () => {clearForm()}}
					color='secondary'
				/>
				</>
			)
	}, [ location, clearForm, sendBookingRequest, updateBookingRequest, setShowDetailsModal] )



	return (
		<div className='prenotazioni-page'>
			<AuthCheck/>
			{

				showEmailModal &&
					<ConfirmModal
						title='Vuoi inviare una email al cliente?'
						text="Se hai modificato l'orario o il metodo di pagamanto e vuoi informare il cliente, conferma l'invio dell' email, altrimenti continua."
						onSuccess={ () =>{
							notifyUpdateHandler()	
							history.push('/')
						}} 
						onCancel={ () => { history.push('/')} }
						successText='Invia'
						cancelText='Continua'
						colorCancel='green'

					/>

			}
			{
				(Gloading || Ploading) &&
					<Loader/>
			}
			{
				showDetailsModal ? 
				<NewBookingModalContainer
					showHandler={ (action) => {
						showModalHandler(action)
						history.push('/')
					}}
					eventId={eventId}
					tipo='evento'
				/>:
				null
			}
			{
				renderFormMask()
			}
			<div className='prenotazioni-btn-wrap'> 
				{ renderButtons() }
			</div>	
		</div>
	)
}


export default FestePrivate
