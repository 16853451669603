import { useWindowSize } from '../Hooks'
import { useState, useEffect, useContext, useCallback } from 'react'
import  MonthChange  from './MonthChange'
import './CalendarContainer.css'
import CalendarDay from '../views/CalendarDay'
import { EventsContext } from '../context/EventsContext'
import { getMonthName, getWeekDay } from '../Methods'
import { useGet } from '../Hooks'
import { BASE_URL } from '../Constants'
import  CalendarModalContainer from './CalendarModalContainer'
import  NewBookingModal from './NewBookingModal'
import Loader from './Loader'
import LimitAlertModal from './LimitAlertModal'
import { MdOutlinePaid } from 'react-icons/md'
import { BsFillTelephoneFill } from 'react-icons/bs'
import { FaChild, FaGlassCheers } from 'react-icons/fa'
import { GiWoodenChair } from 'react-icons/gi'
import { ImArrowUp2 } from 'react-icons/im'
import { FaBirthdayCake } from 'react-icons/fa'
import { GiKnifeFork } from 'react-icons/gi'

const eventsUrl = new URL( BASE_URL()+'/get_calendar_events/')

const CalendarContainer =  ({ reload }) =>{


	const [ showNewBookingModal, setShowNewBookingModal ] = useState( false )
	const [ eventId, setEventId ] = useState(0)
	const [ eventType, setEventType ] = useState('')
	const [ showCalendarModal, setShowCalendarModal ] = useState(false)
	const [ daysNumber, setDaysNumber ] = useState(0)
	const [ selectedMonth, setSelectedMonth ] = useState(0)
	const [ selectedYear, setSelectedYear ] = useState(0)
	const [ monthName, setMonthName ] = useState('')
	const [ fetchEventData, setFetchEventData ] =  useState( {url:null})
	const [ events, setEvents ] = useContext( EventsContext )
	const [ selectedDay, setSelectedDay ] = useState()


	const [ showAlertLimtModal, setShowAlertLimitModal] = useState(false)


	const [ Gdata,
		Gloading,
		Gerror ] = useGet( fetchEventData )

	const getLastDayOfMonth = useCallback( (year, month) =>  {
	  let date = new Date(year, month + 1, 0);
	  return date.getDate();
	}, [])

	const showNewBookingModalHandler = useCallback( ( action, day=null ) => {
		if (day){
			setSelectedDay( day )
		}
		if (action === 'show'){
			setShowAlertLimitModal( false)
			setShowNewBookingModal(true)
		}
		else if ( action === 'hide' ){
			setShowNewBookingModal(false)
		}
	} , [ setSelectedDay, setShowNewBookingModal ])


	const showAlertLimtModalHandler = useCallback( ( action, day=null ) => {
		if (day){
			setSelectedDay( day )
		}
		if (action === 'show'){
			setShowAlertLimitModal(true)
		}
		else if ( action === 'hide' ){
			setShowAlertLimitModal(false)
		}
	} , [ setSelectedDay, setShowAlertLimitModal ])




	const showCalendarModallHandler =  useCallback(   ( action  ) =>{

		if (action === 'show'){
			setShowCalendarModal(true)
		}
		else if ( action === 'hide' ){
			setShowCalendarModal(false)
		}
	}, [ setShowCalendarModal ])

	const yearHandler = useCallback( ( action ) =>{
		if (action === 'add'){
			setSelectedYear( (old) =>{ return ++old })
		}
		else if ( action === 'remove' ){
			setSelectedYear( old => { return  --old } )
		}
	}, [ setSelectedYear ])

	const monthHandler = useCallback( (action ) =>{
		if (action === 'add'){
			setSelectedMonth( (old) =>{ 
				if ( old  === 11 ){
					setSelectedYear( oldYear => { return oldYear + 1  } )
					return 0
				}
				else
					return ++old	
			} )
		}
		else if ( action === 'remove' ){
			setSelectedMonth( old =>{
				if ( old  === 0){
					setSelectedYear( oldYear => { return oldYear - 1 } )
					return 11
				}
				else
					return --old	
			} )
		}
	}, [ setSelectedMonth, setSelectedYear ])

	const showCalendarModalFromEvent = useCallback(  ( id, tipo ) =>{
		setEventId(id)
		setEventType(tipo)
		showCalendarModallHandler('show')
	}, [ setEventId, setEventType, showCalendarModallHandler ])


	useEffect( () =>{
		if ( reload === true ){
			 let openModal = false
			setFetchEventData( { url:null } )
			 if ( showCalendarModal ){
			 	setShowCalendarModal( false )
			 	openModal = true
			 }

			setTimeout( () => {
				const url = new URL( eventsUrl )
				const params = new URLSearchParams({ month:selectedMonth, year:selectedYear })
				url.search = params.toString()

				setFetchEventData({ url:url })

				 if ( openModal ){
				 	setShowCalendarModal(true)
				 }

			}, 100)
		}
	}, [
		reload,
		setFetchEventData, 
		setShowCalendarModal, 
		showCalendarModal,
		selectedYear,
		selectedMonth,
	] )

	useEffect( () =>{
		setMonthName( getMonthName( selectedMonth ) )
		const daysInMonth = getLastDayOfMonth( selectedYear, selectedMonth)
		setDaysNumber( daysInMonth )

		const url = new URL( eventsUrl )
		const params = new URLSearchParams({ month:selectedMonth, year:selectedYear })
		url.search = params.toString()
		setFetchEventData({ url:url })


	},[ selectedMonth, selectedYear, getLastDayOfMonth ])


	useEffect( () =>{
		if (Gdata !== null) setEvents( Gdata )
	},[Gdata, Gloading, Gerror, setEvents] )



	useEffect( () =>{
		const today = new Date() 
		const month = today.getMonth() 
		const year = today.getFullYear()
		setSelectedYear( year )
		setSelectedMonth( month )
		const daysInMonth = getLastDayOfMonth( selectedYear, selectedMonth)
		setDaysNumber( daysInMonth )

		const url = new URL( eventsUrl )
		const params = new URLSearchParams({ month:month, year:year})
		url.search = params.toString()
		setFetchEventData({ url:url })

	}, [] )




	const  windowSize = useWindowSize()

	const renderDays = useCallback(  () =>{
		const daysArray = new Array(daysNumber)
		for ( let i=1; i<=daysNumber; i++ ){
			const dateUsed = new Date( selectedYear, selectedMonth, i )
			const currEvents = []
			if (events !== null && events.length > 0 ){
				 	events.forEach( ( evento ) =>{
					const dataEvento = new Date(evento.data)
					if ( 	
						dataEvento.getFullYear()  === dateUsed.getFullYear() &&
						dataEvento.getMonth() === dateUsed.getMonth() &&
						dataEvento.getDate() === dateUsed.getDate()
					){
						currEvents.push(evento)
					}
				} )
			}
			const day = dateUsed.getDay()
			const dayName = getWeekDay( day ) 
			daysArray.push({ name:dayName, date:dateUsed, dayEvents:currEvents })
		}

		return(
			daysArray.map( ( element, index) =>{
				return (
					<CalendarDay 
						screenWidth = { windowSize.width }
						name = { element.name }
						key={index}
						selfDate = { element.date }
						dayEvents = { element.dayEvents }
						showCalendarModal = { ( id, tipo ) =>
							showCalendarModalFromEvent( id, tipo )
							}
						showNewBookingModal={ ( action   ) => 
							showNewBookingModalHandler(action, element.date.getDate() )
						}
						showAlertLimtModal={() => 
							showAlertLimtModalHandler('show', element.date.getDate() )
						}
					/>		
				)
			})
		)
	}, [ 
		daysNumber,
		selectedMonth,
		selectedYear,
		events,
		showCalendarModalFromEvent,
		windowSize,
		showNewBookingModalHandler,
	] )



	return(
		<div className='calendar-container'>
			{
				Gloading &&
					<Loader/>
			}

			{
				showCalendarModal &&
					<CalendarModalContainer 
						eventId={eventId}
						tipo={ eventType }
						showHandler={ ( action  ) =>showCalendarModallHandler(action) }	
					/>
			}

			{
				showAlertLimtModal &&
					<LimitAlertModal
						showHandler={ show => setShowAlertLimitModal( show )}
						showNewBookingModal = { (show ) => showNewBookingModalHandler(show, selectedDay ) }
					/>
			}

			{
				showNewBookingModal && 
					<NewBookingModal

						showHandler={ ( action, day ) => showNewBookingModalHandler( action, day ) }
						day={ selectedDay }
						month={ selectedMonth }
						year={ selectedYear }
					/>
			}

			<MonthChange 
				currMonth={ monthName }
				currYear={ selectedYear }
				monthHandler={ ( action ) =>  monthHandler( action ) }
				yearHandler={ ( action ) =>  yearHandler( action ) }
			/>

			<div className='days-wrap'>
			{
			renderDays()
			}
			</div>

			<div className='legenda'>
				
				<div>
					<div className='leg leg-birthday'></div>
					<label>Complenno</label>
				</div>

				<div>
					<div className='leg leg-hb'></div>
					<label>Happy Bowling</label>
				</div>

				<div>
					<div className='leg leg-evento'></div>
					<label>Festa privata</label>
				</div>
				<div>
					<MdOutlinePaid/>
					<label>Acconto pagato</label>
				</div>
				<div>
					<FaChild/>
					<label>Con animazione</label>
				</div>
				<div>
					<BsFillTelephoneFill/>
					<label>Da chiamare</label>
				</div>

				<div>
					<FaGlassCheers/>
					<label>Totale aperitivi</label>
				</div>
				<div>
					<GiKnifeFork/>
					<label>Totale pranzi</label>
				</div>
				<div>

					<p > 
					<span style={{ 'color':'var( --orange)'}}>X</span>
						+
					<span style={{ 'color':'var( --purple)'}}>Y</span>
						+
					<span style={{ 'color':'black'}}>ape.</span>
						=
						<span style={{ 'color':'black', 'whiteSpace':'nowrap'}}>TOT bar</span>

					</p>
					<label  style={{ 
						'maxWidth':'20vw',
						'marginLeft':'12px'

					}}>Conteggio per BAR (colorazione eventi)</label>
				</div>

				<div>
					<GiWoodenChair/>
					<label>Numero invitati</label>
				</div>
				<div>
					<FaBirthdayCake/>
					<label>Evento con dolce</label>
				</div>
			</div>
			{
				windowSize.width <= 550 &&
					<div 
						className='go-up'
						onClick={ () => {
							document.querySelector('html').scrollTo(0,0)
						}}
					>

						<ImArrowUp2/>
					</div>

			
			}
		</div>
	)
}



export default CalendarContainer
