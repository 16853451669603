import { BASE_URL } from '../Constants'
import OverviewSection from '../sections/OverviewSection' 
import './CakeOverview.css'



const cakesUrl = new URL ( BASE_URL()+'/get_overview/cake/')

const CakeOverview = () => {




	return(

		<div className='cake-overview'>
			<OverviewSection
				url={cakesUrl}
				cake={ true }
			/>
		</div>
	)


}


export default CakeOverview
