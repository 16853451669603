import { BASE_URL } from '../Constants'
import OverviewSection from '../sections/OverviewSection' 


const eventiUrl = new URL( BASE_URL()+'/get_overview/event/')

const EventiOverview = () => {


	return(
		<div className='overview-page'>



			<OverviewSection 
				url={eventiUrl}
			/>


		</div>
	)


}




export default EventiOverview
