import { useState, useCallback, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { usePost } from '../Hooks'
import EventDetailModal from './EventDetailModal'
import ConfirmModal from './ConfirmModal'
import { BASE_URL } from '../Constants'
import Loader from './Loader'


const deleteUrl = new URL( BASE_URL()+'/delete_booking/')
const setAccontoUrl = new URL( BASE_URL()+'/set_acconto/')
const detailUrl = new URL( BASE_URL()+'/get_event_detail/')
const toggleDaChiamareUrl = new URL( BASE_URL()+'/toggle_da_chiamare/')

const confirmModalTitle = 'Sei sicuro di voler cancellare questa prenotazione'
const confirmModalText = 'Se confermi tutti i dati relativi a questa prenotazione andranno persi'



const CalendarModalContainer = ( { showHandler, eventId, tipo } ) => {

	const [ detailFetchData, setDetailFetchData ] = useState({url:null, query:null})

	const [ Pdata,
		Ploading,
		Perror ] = usePost( detailFetchData )




	const  accontoTitle = useRef('')
	const  accontoText = useRef('')



	const history = useHistory()


	const toggleDaChiamare= useCallback( () =>{
		setDetailFetchData( { 
				url:toggleDaChiamareUrl,
				query:{
					'id':eventId
				}
		})
	}, [ setDetailFetchData, eventId ] )


	useEffect(() => {


		if ( Pdata?.success != null ){
			showHandler('hide')
		}

		//setDetailFetchData({ url:null, query:null })
	}, [ 
		Pdata?.success,
		showHandler,
	])





	const setAccontoBtn = useCallback( () =>{
		if ( 
			Pdata?.booking?.acconto_pagato === false 
			|| Pdata?.evento?.acconto_pagato === false
		){
			accontoTitle.current = "Confermi la ricevuta dell' acconto per questo evento?"
			accontoText.current = "Se confermi verrà inviata un e-mail al cliente per informarlo dell' avvenuta ricezione del pagamento"
			return 'Acconto ricevuto'
		}
		else {
			accontoTitle.current = "Vuoi annullare la ricezione dell' acconto per questo evento?"
			accontoText.current = "Se confermi verrà inviata un e-mail al cliente per informarlo dell' annullamento della ricezione del pagamento"
			return 'Rimuovi acconto'
		}
	} ,[ Pdata ])


	const updateBooking = useCallback( () =>{
		if ( tipo === 'birthday' )
			history.push( '/birthday', { id:eventId, type:tipo } )

		else if ( tipo === 'evento' )
			history.push(  '/evento', { id:eventId, type:tipo } )
	}, [ 
		tipo,
		history,
		eventId,
	] )


	const [ showDeleteConfirm, setShowDeleteConfirm ] = useState( false )
	const [ showAccontoConfirm, setShowAccontoConfirm ] = useState( false )


	const deleteConfirmHandler = useCallback( ( show ) =>{
		show ?
			setShowDeleteConfirm( true ):
			setShowDeleteConfirm( false )
	}, [ setShowDeleteConfirm ] )


	const accontoConfirmHandler = useCallback( ( show ) =>{
		show ? 
			setShowAccontoConfirm( true ):
			setShowAccontoConfirm( false )
	}, [ setShowAccontoConfirm ] )



	useEffect( () => {

		setDetailFetchData({ 
				url:`${detailUrl}${tipo}/`,
				query:{id:eventId},
				})
	}, [])



	const setDaChiamareBtn = useCallback( () => {

		if ( tipo === 'birthday' ){
			if ( Pdata && Pdata.booking?.da_chiamare === false )
				return 'Segna "da chiamare"'
			else if ( Pdata && Pdata.booking?.da_chiamare === true )
				return 'Togli "da chiamare"'

		}
		else if ( tipo === 'evento' ){
			if ( Pdata && Pdata.evento?.da_chiamare === false )
				return 'Segna "da chiamare"'
			else if ( Pdata && Pdata.evento?.da_chiamare === true )
				return 'Togli "da chiamare"'
		}
		return null

	}, [ Pdata, tipo ] )

	return( <>
		{
			(Ploading === true  || Pdata == null) ?
				<Loader/>:
			<EventDetailModal
				listValues={Pdata}
				showHandler={showHandler}
				btnAction={ () => updateBooking() }

				showDeleteHandler={ ( show ) => deleteConfirmHandler( show )  }
				showAccontoHandler={ ( show ) => accontoConfirmHandler( show )  }
				btnText='Modifica'
				btn1Text={ setAccontoBtn() }
				btn2Text='Elimina'
				btn3Text={ setDaChiamareBtn() }
				toggleDaChiamare={ () => toggleDaChiamare() }
			>
				{
					showDeleteConfirm &&
					<ConfirmModal 
						onSuccess={ () => {
							setDetailFetchData( old =>{
								return {
									...old,
									url:deleteUrl,
									query:{
										id:eventId
									}
								}
							} )
							//showHandler('hide')
						} }
						onCancel={ () => showHandler('hide') }
						title={confirmModalTitle}
						text={ confirmModalText }
					/>
				}

				{
					showAccontoConfirm &&
					<ConfirmModal 
						onSuccess={ () => {
							setDetailFetchData( old =>{
								return{
									url:setAccontoUrl,
									query:{
										//'isPayed': !Pdata?.booking?.acconto_pagato ?? !Pdata?.evento?.acconto_pagato,
										'id':eventId
									}
								}
							})
						} }
						onCancel={ () => showHandler('hide') }
						title={accontoTitle.current}
						text={ accontoText.current}
						/>
				}

			</EventDetailModal>
		}
		</> ) 
}



export default CalendarModalContainer
