import { useState, useEffect, useRef, useCallback, useContext } from 'react'

import TextInput  from '../views/TextInput'
import TextInputList from '../views/TextInputList'
import TextArea from '../views/TextArea'
import Divider from '../views/Divider'
import DropDown from '../views/DropDown'
import './FormMask.css'
import { formatHint } from '../Methods'
import {UserContext} from '../context/UserContext'
import {useHistory} from 'react-router-dom'
import {hydrate} from 'react-dom'



const FormMask = ({	initialFormData,
			title,
			selects,
			formValues,
			setFormValues,
			setFormValuesByEvent,
			setFormNomiFesteggiati,
			setFormEtaFesteggiati,
			group,
			show,

}) =>{


	const history = useHistory()


	

	const [ user, ] = useContext(UserContext)

	const formRef = useRef()
	const [ quantityName, setQuantityName ] = useState(0)



	useEffect( () => {


		if ( user.solo_lettura !== false ){
			history.push('/')
		}

	}, [])

	useEffect( () =>{
		let localFormDataParsed = {}
		const localFormData = sessionStorage.getItem(group)

		if( localFormData !== null){
			localFormDataParsed  = JSON.parse( localFormData )
		}


		sessionStorage.setItem(group,
			JSON.stringify(
				{
				...localFormDataParsed,
				...formValues
				}
			)
		)
		

		if (formValues.numero_festeggiati !== undefined ){
			if ( /\d+/.test(formValues.numero_festeggiati) ){
				setQuantityName( 
					parseInt(formValues.numero_festeggiati)
				)
			}
			else
				setQuantityName(0)
		}



	}, [formValues, group] )





	const setType =  useCallback( (id) => {
		switch(id){
			case 'data':
				return 'date'
			case 'ora':
				return 'time'
			default:
				return 'text'
			}
	}, [] )


	const checkIsDigit = useCallback( ( dict, key ) =>{
		if ( 
			dict[key] !== null && 
			dict[key].digit !== null && 
			dict[key].digit === true 

		){
			return true
		}
		else{
			return false
		}

	}, [] )

	const renderInput = useCallback( () => {
		return(
			Object.keys( initialFormData ).map( (id, index) =>{

				switch(id){
					case 'note':
					case 'allergie':
						return(
							<TextArea   
								key={ index }
								name={id} 
								hint={ 
									id === 'allergie'?
									'Allergie/Note'	:
									formatHint(id) 
								}
								text={ formValues[id] }
								onchange={ event => 
									setFormValuesByEvent(event, group)
								}
							/>
						)

					case 'eta_festeggiati':
					case 'nome_festeggiati':
						return(
							<TextInputList  
								quantity={quantityName}
								key={index}
								text={ formValues[id] }
								id={id}
								onchange={ event =>{
									id === 'nome_festeggiati' ?
										setFormNomiFesteggiati(event, group):
										setFormEtaFesteggiati( event, group )
								}}
							/>
						)	

					default:
						return (
							<TextInput   
								key={ index }
								name={id} 
								hint={ formatHint(id) }
								text={ formValues[id]  }
								onchange={ event => {
									setFormValuesByEvent(event, group)
								}
								}
								type={setType(id)}
								digit={	checkIsDigit(initialFormData, id) }
							/>
						)
				}
		}))
	}, [	initialFormData,
		formValues,
		checkIsDigit, 
		setType, 
		setFormValuesByEvent, 
		setFormNomiFesteggiati, 
		quantityName,
		formatHint,
		group,
	])


	const renderSelects = useCallback(  () =>{
		return (
			Object.keys( selects ).map( (element, index) =>{
				return(
					<DropDown
						key={ index }
						name={ element }
						onchange={ event => 
							setFormValuesByEvent(event, group)
						}
						options={ selects[element] }
						text={ formValues[element] }
						hint={ formatHint( element ) }
					/>
				)
			} )
		)
	}, [ selects, setFormValuesByEvent, formValues, formatHint ] )


	if (show) return (
		<>
		<Divider title={ title }/>	
			<div 
				className='form'
				ref={formRef}
			>
		{
			selects !== undefined ?
			renderSelects():
			null
		}
		{
			initialFormData !== undefined ?
			renderInput():
			null
		}
			</div>
		</>
	)
	else return ( <></> )
}



export default FormMask




