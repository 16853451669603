import  { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useHistory, Redirect } from 'react-router-dom'
import './NavMenu.css';
import { UserContext } from '../context/UserContext'
import MenuItemDropdown from './MenuItemDropdown'
import { isDictUndef } from '../Methods'


import {  IoCalendarNumberOutline } from 'react-icons/io5'


const NavbarMenu = ({ active, updateClick ,closeMenu}) => {

        const [name, setName] = useState('')
	const navMenuRef = useRef()
	const history = useHistory()

	const [ user, setUser ] = useContext( UserContext )


	const setClassesMenu = useCallback(  ()=> {
		if (active === true) 
			return 'menu navbar-active'
		else 
			return 'navbar-deactive'
	}, [  active ])


	useEffect( () => {
		if ( user ){
			if ( 
				user !== undefined &&
				user.name !== undefined
			){
				setName(user.name)
			}
			else  {
				setName('')
			}
		}
		if ( isDictUndef(user) ){
			history.push('/login')
		}
	},[user] )



	const setAccountMenu = useCallback(  () =>{
		if ( !isDictUndef( user ) ){
			if ( user.is_admin ){
				return [ ['pannello admin', 'logout'], ['/','/login'] ]	
			}
			else if ( !user.is_admin ){
				return [ ['logout'], ['/login'] ]		
			}
		}
		return [ [],[] ] 
	})



	if (user !== undefined && user.name !== undefined ){  
                return(
                <ul 
			className={ `navbar-mobile ${setClassesMenu()} ` } 
                        ref={ navMenuRef }
		>

			<li 
				className='menu-item'
				onClick={ () =>{
					history.push('/')
					closeMenu( active, navMenuRef, updateClick )
					}
				}
			>
				<div className='menu-text'>
					<IoCalendarNumberOutline style={{'fontSize':'1.5rem'}}/>
					<p>
						Calendario
					</p>
				</div>
			</li>
				
			
			
			<MenuItemDropdown 
				closeMenu={ () => closeMenu(  active, navMenuRef, updateClick ) }
				subMenuList={ ['compleanni','eventi','torte'] }
				to={ ['/overview','/eventi_overview', '/cake_overview'] }
				labelText='Report'
			/>
			
			<MenuItemDropdown 
				closeMenu={ () => closeMenu( active, navMenuRef, updateClick ) }
				subMenuList={  setAccountMenu()[0] }
				to={ setAccountMenu()[1] }
				labelText={ name }
			/>
                </ul>
                )
                } 

	else{
		return ( <Redirect to='/login'/> )
	}

}

export default NavbarMenu;


