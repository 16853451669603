import { useEffect, useState, useCallback } from 'react'

import { useHistory } from 'react-router-dom'
import Button from '../views/Button'

import Modal from './Modal'
import './NewBookingModal.css'



const NewBookingModal = ({ showHandler, day, month, year }) => {


	const history = useHistory()
	const [ bookingDate, setBookingDate ] = useState()

	const fixDigitLen = useCallback( ( digit ) =>{
		const digitChar = digit.toString()
		if ( digitChar.length < 2 ){
			return `0${digitChar}`
		}
		return digit

	}, [] )

	useEffect( ()=>{
		const date = `${year}-${fixDigitLen(month+1)}-${ fixDigitLen(day) }`
		setBookingDate( date )
	},[ day, month, year, setBookingDate, fixDigitLen ] )



	return (

		<Modal>
			<div
				className='modal-container'
				onClick={ () => showHandler( 'hide' ) }
			>
				<div
					className='new-modal'
				>

					<h2>Nuova prenotazione</h2>
					<p>che tipo di evento sarà?</p>
					<div>
						<Button
							text='Compleanno'
							onclick={ () => {
								history.push('/birthday', { date:bookingDate })  
							}}
						/>

						<Button
							text='Festa privata'
							onclick={ () => {
								history.push('/evento', { date:bookingDate }) 
							}}
						/>
					</div>
				</div>
			</div>
		</Modal>
	)
}



export default NewBookingModal
