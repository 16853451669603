import { BASE_URL } from '../Constants'
import OverviewSection from '../sections/OverviewSection' 
import './BookingsOverview.css'


const bookingsUrl = new URL (BASE_URL()+'/get_overview/booking/')


const BookingsOverview = () => {


	return(
		<div className='overview-page'>
			<OverviewSection 
				url={bookingsUrl}
			/>
		</div>
	)
}




export default BookingsOverview
