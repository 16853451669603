


import './MonthChange.css'

import ArrowButton from '../views/ArrowButton'






const MonthChange = ( { currMonth, currYear, monthHandler, yearHandler } ) =>{




	return (
		<div className='month-change'>
			<div className='picker'>
				<ArrowButton 
					onclick={ () => monthHandler('remove') }
					left={true}
				/>
					<span>
						{currMonth}
					</span>
				<ArrowButton 
					onclick={ () => monthHandler('add') }
					right={true}
				/>
			</div>

			<div className='picker'>
				<ArrowButton 
					onclick={ () => yearHandler('remove') }
					left={true}
				/>
					<span>
						{ currYear }
					</span>
				<ArrowButton 
					onclick={ () => yearHandler('add') }
					right={true}
				/>
			</div>
		</div>
	)
}



export default MonthChange
