
export const BASE_URL = () => setBaseUrl() 

export const BASE_WS = () => setBaseWs() 




const setBaseUrl = () => {
	if ( window.location.port === '3000' ){
		//return `http://192.168.1.184:8000/api`
		return `http://127.0.0.1:8000/api`
	}
	else{
		return `${window.origin}/api`
	}
}


const setBaseWs  = () => {
	if ( window.location.port === '3000' ){
		//return `ws://192.168.1.184:8000/ws`
		return `ws://127.0.0.1:8000/ws`

	}
	else{
		return `${window.location.protocol === 'https:' ? 'wss://' : 'ws://'}${window.location.host}/ws`
	}
}



export const initialCustomerData = {
	'nome_cliente':null,
	'cognome':null,
	'numero_festeggiati':{digit:true},
	'nome_festeggiati':null,
	'eta_festeggiati':null,
	'numero_invitati':{digit:true},
	'telefono':{digit:true},
	'email':null,
	'note':null,
	'ora':{ digit:true },
	'data':null,
}


export const initialSelectCustomerData = {
	aperitivo_genitori:[ {text:'no', value:'False'}, { text:'si', value:'True'}],
	dolce:[ {text:'no',value:'False'}, {text:'si', value:'True'}],
	tipo_pagamento:[
		{ text:'contanti', value:'CT'},
		{text:'bonifico', value:'BN'},
		{text:'satispay',value:'SP'}
	],
	regalo_extra:[
		{ text:'nessuno', value:'NO'},
		{text:'birillo', value:'BR'},
		{text:'orologio',value:'OR'}
	]
}


export const initialParentData = {
	numero_aperitivi:{digit:true},
}



export const initialSelectParentData = {
}



export const initialCakeData = {
	scritta:null,
	numero_pezzi:{digit:true},
	allergie:null,
	porzioni_extra:null

}



export const initialSelectCakeData = {

	tipo:[
		{text:'torta',value:'TR'},
		{text:'focaccia', value:'FC'}
	],

	base:[
		{text:'nessuna', value:'NS'},
		{text:'pandispagna',value:'PS'},
		{text:'sfoglia',value:'SF'},
	],
	farcitura:[
		{text:'nessuna', value:'NS'},
		{text:'crema pasticcera',value:'CP'},
		{text:'chantily', value:'CY'},
		{text:'gianduja',value:'GJ'},
		{text:'cioccolato',value:'CO'},
		{text:'chantily fragole',value:'CF'}
	],
	copertura:[
		{text:'nessuna', value:'NS'},
		{text:'panna',value:'CO'},
		{text:'cioccolato', value:'PN'}
	],
	
	cialda:[
		{text:'no',value:'False'},
		{text:'si',value:'True'}
	]

}



export const initialEventoData = {

	'nome_cliente':null,
	'cognome':null,
	'numero_invitati':{digit:true},
	'telefono':{digit:true},
	'email':null,
	'note':null,
	'ora':{ digit:true },
	'data':null,
}


export const initialSelectEventoData = {
	

	locale:[ {text:'saletta',value:'SL'}, {text:'dehor',value:'DR'} ],
	tipo_evento:[ {text:'addio celibato',value:'AC'}, {text:'cena impresa',value:'CI'} ],
	bambini:[ {text:'no',value:'False'}, {text:'si', value:'True'}],
	dolce:[ {text:'no',value:'False'}, {text:'si', value:'True'}],
	tipo_pagamento:[
		{ text:'contanti', value:'CT'},
		{text:'bonifico', value:'BN'},
		{text:'satispay',value:'SP'}
	],
}



export const NOT_PRINT_FIELD = [
				'user',
				'tipo_pagamento',
				'email',
				'numero_festeggiati',
			//	'nome_festeggiati',
				'animazione',
				'regalo_extra',
				'is_active',
]


