import './EmailPasticceriModal.css'


import Modal from '../sections/Modal'
import Button from '../views/Button'


const EmailPasticceriModal = ({ showHandler, pasticcere, sendEmail}) =>{


	return(
		<Modal>
			<div
				className='modal-container'
				onClick={ () => showHandler( false ) }
			>
				<div className='email-modal'>
					<h3>Scegli pasticceri</h3>
					<p>I dolci visualizzati verrano inviati a:</p>

					<p>{pasticcere.nome} - {pasticcere.email}</p>

					<div className='email-btn-wrap'>
						<Button
							text='Invia'
							onclick={ () =>{ 
								sendEmail()
								showHandler(false)
							}}
						/>

						<Button
							text='Annulla'
							color='secondary'
							onclick={ () => showHandler(false) }
						/>

					</div>
				</div>
			</div>
		</Modal>
	)
}



export default EmailPasticceriModal
