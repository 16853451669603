import  {useRef,  useState, useEffect,  useLayoutEffect, useContext, useCallback } from 'react'
import { useHistory  } from 'react-router-dom'
import {  isDictUndef,  } from './Methods'
import { UserContext } from './context/UserContext'

import { BASE_URL } from './Constants'


// class FetchError extends Error{
// 	constructor( message, data ){
// 		super(message)
// 		this.message = message
// 		this.data = data
// 	}
// }


const baseHeaders =  new Headers({
			 'Content-Type':'application/json',
			 'Accept':'application/json',
			})






const useRecursiveFetch = () => {

        const [ , setUser ] = useContext( UserContext )

        return useCallback( async (  url, query,  signal, accessToken, method )=> {
                baseHeaders.set( 'Authorization',  `Bearer ${accessToken}` )


                const request = new Request( url, {
                        method: method,//query != null ? 'POST' : 'GET',
                        signal:signal,
                        headers:baseHeaders,
                        ...( query != null &&  { body : JSON.stringify( query )} ),
                })



                return await fetch( request )
                        .then( resp =>{
                                if (resp.ok){
                                        return resp.json( resp )
                                                .then( data =>{
                                                        return data
                                                } )
                                }
                                else{
                                        return resp.json()
                                                .then( error => {
                                                        return { 'error': error }
                                                })
                                }
                        })
                        .catch ( error =>{
                                return { 'error': error }
                        })
                        .finally( _ => {

				 setUser( old =>{
						return {
							...old,
							access:accessToken
						}
				 })
                                // setUser( old => {
                                //         return {
                                //                 ...old,
                                //                 [ header ]:{ 
                                //                         ...old[header],
                                //                         access:accessToken
                                //                         }
                                //         }
                                // })
                        } )
        }, [  setUser ]  )
}



const useRefreshToken = () =>{

        const recursiveFetch = useRecursiveFetch()
//        const doLogout = useLogout()


	const history = useHistory()
	const [ , setUser ] = useContext( UserContext )


        return useCallback( async(url, query, signal, method ) => {
		const refreshUrl = `${ BASE_URL() }/token/refresh/`
		baseHeaders.delete( 'Authorization' )

		if ( ! sessionStorage.user ) return 

		let user = JSON.parse(sessionStorage.user)
		const queryRefresh = JSON.stringify( { 'refresh':user.refresh } )


                const request = new Request( refreshUrl, {
                        method:'POST',
                        headers: baseHeaders,
                        body:queryRefresh,
                        signal:signal,
                })

                return await fetch(  request )
                        .then( resp => {
                                if ( resp.ok ){
                                        return resp.json()
                                                .then( data => {
                                                        return recursiveFetch( 
                                                                url,
                                                                query,
                                                                signal,
                                                                data.access,
                                                                method,
                                                        )
                                                                .then( data =>{

                                                                        return data
                                                                })
                                                })
                                }
                                else{
					 sessionStorage.removeItem( 'user' )
					 history.push( '/login' )
					 setUser({})
                                        //doLogout()
                                        // return { error: resp.error } 
                                }
                        })
        }, [ 
                //doLogout,
                // headers.empty,
                recursiveFetch,
		history,
		setUser,
                //setUser,
        ] )
}

export const useGet = ({ url, }) => {

        const [ data, setData ] = useState( null )
        const [ error, setError ] = useState( null )
        const [ loading, setLoading ] = useState( false )
        const controller = useRef()

        const refreshToken = useRefreshToken()
        const startedRef = useRef( false )



        useEffect(() => {

                if( 
                        url != null 
                        && startedRef.current === false
                ) {

                        startedRef.current = true
                        setLoading( true )
                        setError( null )
                        setData( null )
                        controller.current &&
                                controller.current.abort()
                        controller.current = new AbortController() 

                        const request  = new Request( url, {
                                method:'GET',
                                signal:controller.current.signal,
                                headers:baseHeaders
                        })



                        fetch( request )
                                .then( resp => {
                                        if ( resp.ok ){

						resp.json().then( data =>{
							setData( data )
						})
						.catch( err => {
                                        		console.log( 'error in fetch', err )
							setError( err )
						})
                                        }
                                        else{
                                                resp.json().then( error => {
                                                        if ( Object.values(error ).includes('token_not_valid') ){
                                                                refreshToken( 
                                                                        url,
                                                                        null,
                                                                        controller.current.signal ,
                                                                        'GET',
                                                                )
                                                                        .then( data => {
                                                                                if ( data?.error != null ){
                                                                                        setError( data.error )
                                                                                }
                                                                                else{
                                                                                        setData( data )
                                                                                }
                                                                        })
                                                                        .catch( error =>{
                                                                                console.log( 'error in fetch', error )
                                                                                setError( error )
                                                                        })
                                                        }
                                                        else{
                                                                setError( error )
                                                        }
                                                })
                                                .catch( err => {
                                                        setError( err )
                                                })
                                        }
                                })
                                .catch( error => {
                                        console.log( 'error in fetch', error )
                                        setError( error )
                                })
                }
        }, [   
		refreshToken,
                url, 
                setLoading,
                setError,
                setData,
        ])


        useEffect( () => {
                if ( data != null || error != null ){
                        setLoading(false)
                        startedRef.current = false
                }
        }, [
                data,
                error,
                setLoading,
        ] )

        useEffect( () => {
                return () => {
                        controller.current &&
                                controller.current.abort()
                }
        }, [])

        return [ data, loading, error ]

}


export const usePost = ({ url, query, }) => {

        const [ data, setData ] = useState( null )
        const [ error, setError ] = useState( null )
        const [ loading, setLoading ] = useState( false )                                                              
        const controller = useRef()                                                                                    
                                                                                                                       
        const  refreshToken = useRefreshToken()                                                                        
                                                                                                                       
	const [ user, ] = useContext( UserContext )


	if(  isDictUndef( user  )){
		baseHeaders.delete( 'Authorization')
	} else{
		
		baseHeaders.set( 'Authorization', `Bearer ${user.access.trim()}` )
	}
                                                                                                                       
        const startedRef = useRef(false)                                                                               
                                                                                                                       
        const urlRef = useRef('')                                                                                      
        //const queryRef = useRef('')                                                                                    
                                                                                                                       
        useEffect( () => {                                                                                             


		// console.log( 'urlRef', urlRef.current )
		// console.log( 'url', url)
		// console.log( 'startedRef', startedRef.current )
		// console.log( 'query', query)
  //               console.log( url != null )
		// console.log( query != null  )
		// console.log( startedRef.current === false  )
  //               console.log( urlRef.current !== url?.href  )
                                                                                                                       
                if(                                                                                                    
                        url != null                                                                                    
                        && query != null                                                                               
                        && startedRef.current === false                                                                
                        &&                                                                                            
                                urlRef.current !== url?.href                                                            
                                //|| queryRef.current !== JSON.stringify( query )                                        
                                                                                                                      
                ) {                                                                                                    
                                                                                                                       
			// console.log( 'post started ')
                        controller.current != null && controller.current.abort()                                       
                        controller.current = new AbortController()                                                     
                        //queryRef.current = JSON.stringify( query )                                                     
                        urlRef.current = url.href                                                                      
                        startedRef.current = true                                                                      
                        setLoading( true )                                                                             
                        setError( null )                                                                               
                        setData( null )                                                                                
                        const request  = new Request( url, {                                                           
                                method:'POST',                                                                         
                                body:JSON.stringify( query ),                                                          
                                signal:controller.current.signal,                                                      
                                headers: baseHeaders,                                                                   
                        })                                                                                             
                        fetch( request )                                                                               
                                .then( resp => {                                                                       
                                        if ( resp.ok ){                                                                

						resp.json().then( data =>{
							setData( data )
						})
						.catch( err => {
                                        		console.log( 'error in fetch', err )
							setError( err )
						})
                                        }
                                        else{
                                                resp.json().then( error => {
                                                        if ( Object.values(error ).includes('token_not_valid') ){
                                                                refreshToken( 
                                                                        url,
                                                                        query,
                                                                        controller.current.signal,
                                                                        'POST',
                                                                )
                                                                        .then( data => {
                                                                                if ( data?.error != null ){

                                                                                        setError( data.error )
                                                                                }
                                                                                else{
                                                                                        setData( data )
                                                                                }
                                                                        })
                                                                        .catch( error =>{
                                                                                setError( error )
                                                                        })
                                                        }
                                                        else{
                                                                setError( error )
                                                        }
                                                })
						.catch( err => {
                                        		console.log( 'error in fetch', err )
							setError( err )
						})
                                        }
                                })
                                .catch( error => {
                                        console.log( 'error in fetch', error )
                                        setError( error )
                                })
                }
                else{
                        urlRef.current = ''
                        //queryRef.current = ''
                }


        }, [    url, 
                query,
                refreshToken,
                setLoading,
                setError,
                setData,
        ])


        useEffect( () => {


                if ( data != null || error != null ){
                        setLoading(false)
                        startedRef.current = false
                }
        }, [
                data,
                error,
                setLoading,
        ] )


        useEffect( () => {
                return () => {
			if ( controller.current != null){

				startedRef.current === true &&    controller.current.abort()
			}
                }
        }, [] )

        return [ data, loading, error ]

}










































// const refreshToken = async () =>{
// 	const refreshUrl = `${ BASE_URL() }/token/refresh/`
// 	baseHeaders.delete( 'Authorization' )
//
// 	if ( ! sessionStorage.user ) return 
//
// 	let user = JSON.parse(sessionStorage.user)
// 	const query = JSON.stringify( { 'refresh':user.refresh } )
//
// 	const refreshData = {
// 		method:'POST',
// 		headers:baseHeaders,
// 		body:query
// 	}
// 	
// 	return  await fetch( refreshUrl, refreshData)
// 		.then( resp => {
// 			if ( resp.ok ){
// 				return resp.json()
// 					.then( data =>{
// 						user = {
// 							...user,
// 							access:data.access
// 						}
// 						sessionStorage.setItem('user', JSON.stringify(user))
// 						baseHeaders.set('Authorization', `Bearer ${data.access}`)
// 						return  data
// 					})
// 			}
// 			else{
// 				return resp.json()
// 					.then( Rerror => {
// 						return {error : Rerror}
// 					})
// 			}
// 		})
// }
//
//
//
// const recursiveFetch = async ( url, Fmethod,  query=null, setData, setError, signal, history, setUser, recursive=false ) => {
// 	let fetchData
// 	if ( Fmethod === 'GET' ) fetchData = {
// 					method:Fmethod,
// 					headers:baseHeaders,
// 					}
// 	else if ( Fmethod === 'POST' ) fetchData = {
// 					method:Fmethod,
// 					headers:baseHeaders,
// 					body:JSON.stringify( query )
// 					}
//
// 	return await fetch( url, fetchData, signal )
// 	 .then( resp =>{
//
// 		 setError( null )
// 		 setData( null )
//
// 		 if (resp.ok){
// 			resp.json()
// 				 .then( data => {
// 					 setData( data )
// 				 })
// 		 }
// 		 else resp.json().then( errors => {
// 			 if ( Object.values( errors ).includes('token_not_valid') && !recursive ){
// 				 refreshToken().then( resp =>{
// 						 if ( ! resp.error ){
// 							 setUser( old =>{
// 									return {
// 										...old,
// 										access:resp.access
// 									}
// 							 })
// 							 recursiveFetch( 
// 								 	url,
// 								 	Fmethod,
// 								 	query,
// 								 	setData,
// 								 	setError,
// 								 	signal,
// 								 	history,
// 								 	setUser,
// 								 	true
// 							 		)
// 						 }
// 						 else{
// 							 setError( resp.error )
// 						 }
// 					 })
// 			 }
// 			 else if ( Object.values( errors ).includes('token_not_valid') && recursive ){
// 				 sessionStorage.removeItem( 'user' )
// 				 history.push( '/login' )
// 				 setUser({})
// 			 }
// 			 else{
// 				 setError( errors )
// 			 }
// 		 }) 
// 	 })
// } 
//
//
//
//
// export const useGet = ( url ) => {
// 	const [ Gdata, setData ] = useState(null)
// 	const [ Gloading, setLoading ] = useState(false)
// 	const [ Gerror, setError ] = useState(null)
// 	const [user, setUser]  =  useContext(UserContext)
// 	const controller = new AbortController()
// 	const signal = controller.signal
// 	const history = useHistory()
//
//
// 	if( isDictUndef(user) ){
// 		baseHeaders.delete( 'Authorization')
// 	} else{
// 		baseHeaders.set( 'Authorization', `Bearer ${user.access}` )
// 	}
//
// 	useEffect( () =>{
// 		if (url){
//
// 			setLoading(true)
// 			recursiveFetch( url,
// 					'GET',
// 					null,
// 					setData,
// 					setError,
// 					signal,
// 					history,
// 					setUser,
// 					)
// 				.then( _ => 
// 					setLoading(false)
// 				)
// 		}
//
// 	}, [ url  ] )
//
//
// 	useEffect( () => {
// 		return () =>{
// 			controller.abort()
// 		}
// 	}, [] )
//
//
// 	return { Gdata, Gloading, Gerror }
// }
//
//
// export const usePost = ({ url, query }) => {
// 	const [ Pdata, setData ] = useState(null)
// 	const [ Ploading, setLoading ] = useState(false)
// 	const [ Perror, setError ] = useState(null)
//
// 	const [ user, setUser ] = useContext( UserContext )
// 	const controller = new AbortController()
// 	const signal = controller.signal
//
//
// 	const history = useHistory()
//
// 	if(  isDictUndef( user  )){
// 		baseHeaders.delete( 'Authorization')
// 	} else{
// 		
// 		baseHeaders.set( 'Authorization', `Bearer ${user.access.trim()}` )
// 	}
//
// 	useEffect( () =>{
// 		if ( url && query ){
// 			setLoading(true)
// 			recursiveFetch( url,
// 					'POST',
// 					query,
// 					setData,
// 					setError,
// 					signal,
// 					history,
// 					setUser,
// 					)
// 				.then( _ =>{
// 					setLoading(false)
// 				})
// 		}
// 	}, [ url, query ] )
//
//
// 	useEffect( () => {
// 		return () =>{
// 			controller.abort()
// 		}
// 	}, [] )
//
// 	return { Pdata, Ploading, Perror }
// }
//





export const useForm = ( initialValues ) => {

	const [ formValues, setFormValues ] = useState( () => initialValues )


	// Debug
	// useEffect( () =>{
	// 	console.log( 'formValues from hoook ',formValues )
	// }, [formValues] )

	const setFormValuesByEvent = useCallback( (event, group) =>{

					
		if ( group !== undefined ){
			// if ( event.target.value === '' ){
			// 	setFormValues( values =>{
			// 		delete values[group][event.target.name]
			// 		return { ...values }
			// 	})
			// }
			// else
				setFormValues( (values) => {
					return {
						...values,
						[group]:{
							...values[group],
							[event.target.name]:event.target.value
						}
					}
				})
		}
		else {
			// if ( event.target.value === '' ){
			// 	setFormValues( values =>{
			// 		delete values[event.target.name]
			// 		return { ...values }
			// 	})
			// }
			// else
				setFormValues( (values) => {
					return {
						...values,
						[event.target.name]:event.target.value
					}
				})
		}
	}, [ setFormValues ] )




	const setFormNomiFesteggiati = useCallback(  (event, group) => {

			setFormValues( (values) =>{
				if ( event.target.value.trim() === '' ){
					if( values[group]['eta_festeggiati'] ){
						delete values[group]['nome_festeggiati'][event.target.name]
						return { ...values }
					}
					return { ...values }
				}
				else
				return {
					...values,
					[group]:{
						...values[group],
						nome_festeggiati:{
							...values[group].nome_festeggiati,
							[event.target.id]:event.target.value
						}
					}
				}
			} )
	}, [ setFormValues ] )



	
	const setFormEtaFesteggiati = useCallback( ( event, group ) =>{
		setFormValues( values =>{
				if ( event.target.value.trim() === '' ){
					if( values[group]['eta_festeggiati'] ){
						delete values[group]['eta_festeggiati'][event.target.name]
						return { ...values }
					}
					return { ...values }

				}
				else
				return {
					...values,
					[group]:{
						...values[group],
						eta_festeggiati:{
							...values[group].eta_festeggiati,
							[event.target.id]:event.target.value
						}
					}
		}})
	}, [ setFormValues ])



	return [
		formValues,
		setFormValues,
		setFormValuesByEvent,
		setFormNomiFesteggiati,
		setFormEtaFesteggiati,
	]

} 




export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useLayoutEffect(() => {
	  const handleResize = () => {
	      setWindowSize({
		width: window.innerWidth,
		height: window.innerHeight,
	      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []); 
  return windowSize;
}












