
import Modal from "./Modal"
import Button from '../views/Button'



const LimitAlertModal = ({ showHandler, showNewBookingModal }) => {



	return (

		<Modal>
			<div
				className='modal-container'
				onClick={ () => showHandler( false ) }
			>
				<div
					className='new-modal'
				>

					<h2 
						style={{
							'color':'red'
						}}
					>ATTENZIONE LIMITE RAGGIUNTO</h2>
					<p>Ci sono già 130 persone per questa giornata,  vuoi comunque procedere con la prenotazione?</p>
					<div>
						<Button
							text='Continua'
							color='green'
							onclick={ () => {
								showNewBookingModal('show')
							}}
						/>

						<Button
							text='Annulla'
							color="secondary"
							onclick={ () => {
								showHandler( false )
							}}
						/>
					</div>
				</div>

			</div>
		</Modal>
	)
}


export default LimitAlertModal
