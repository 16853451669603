
import './Button.css'





const ripple = ( event ) => {

	const btn_parent = event.target
	const x = event.clientX - event.target.offsetLeft 
	const y = event.clientY - event.target.offsetTop + window.scrollY
	;

	const span = document.createElement('span')
	span.classList.add( 'ripple')
	span.style.left = `${x}px`;
	span.style.top = `${y}px`;
	btn_parent.appendChild(span)

	setTimeout( () => {
		span.remove() ;
	},500 )
}

const Button = ({ text, onclick, color='primary'  }) => {




	return(
	
		<button 
			className={ `btn ${color}` }
			onClick={ (event) =>{
				ripple(event)
				setTimeout( () =>{

					onclick()

				},100)
			}

			}
		>
		{ text }
		</button>
	)
}



export default Button
