import {useState, useEffect, useCallback, useContext } from 'react';
import {Link} from 'react-router-dom';
import './Navbar.css';
import NavbarMenu from './NavMenu';
import {FaBars, FaTimes} from 'react-icons/fa';
import { isDictUndef } from '../Methods'
import { UserContext } from '../context/UserContext'





const Navbar = ({ pageRef }) => {
        const [click, setClick] = useState(false);
	const [ rerender, setRerender ] = useState(false)
	const [ user, setUser ] = useContext( UserContext )

	useEffect ( () => {
		if (click)
			document.body.style.overflowY='hidden';
		else
			document.body.style.overflowY='auto';
	},[click] )


	useEffect( () => {
		if (pageRef  != null) pageRef.current.onclick = () =>{
				closeMenu()
		} 
		return () => {
			pageRef.current.onclick = null
		}
	}, [])

	const closeMenu = useCallback(  ()=> {
		const menu = document.querySelector('.menu');
		const dropdowns = document.querySelectorAll('.dropdown-menu')

		for (let dropdown of dropdowns){
			dropdown.classList.remove('dropdown-active')
			dropdown.classList.add('dropdown-deactive')
			dropdown.style.height = '0'
		}
		if(menu)
			menu.classList.remove('navbar-active');
		setClick(false);
			
	}, [ setClick ] )
		

        const handleClick = useCallback( () => { 
		if ( !click){
			closeMenu( )
		}
			setClick(!click) 
	}, [ click, setClick, closeMenu ])



        return(
		<div className={ 'navbar'} >
			<Link className='navbar-logo' to='/' onClick={() => closeMenu()}>
				<h1>King</h1>
			</Link>
			{
				! isDictUndef( user ) &&
						<>
						<div className='navbar-icon' onClick={handleClick}>
							{ click ? <FaTimes/> : <FaBars/> }
						</div>      
								<NavbarMenu 
									active={click}
									updateClick={handleClick}
									closeMenu={ () => closeMenu() }
								/>
						</>
			}
                </div>
        );          
}

export default Navbar;

