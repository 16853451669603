

import LoginContainer from '../sections/LoginContainer'
import './Login.css'


const Login = () =>{


	return(
		
		<div className='card-page'>


			<LoginContainer/>

		</div>

	)
}


export default Login
