import Modal from './Modal'
import './Loader.css'



const Loader = () => {



	return(
		<Modal>
			<div className='modal-container'>



				<div className='loader-wrap'>


				<div className='loader-circle'></div>
				<div className='loader-circle'></div>
				<div className='loader-circle'></div>
				<div className='loader-circle'></div>


				</div>

			</div>
		</Modal>
	)
}



export default Loader
