import { useState, useEffect } from 'react'
import EventDetailModal from './EventDetailModal'
import { BASE_URL } from '../Constants'
import { showError } from '../Methods'
import { usePost } from '../Hooks'


const detailUrl = new URL( BASE_URL()+'/get_event_detail/') 

const NewBookingModalContainer = ( { showHandler, eventId, tipo } ) => {

	const [ detailFetchData, setDetailFetchData ] = useState({ 
								url:`${detailUrl}${tipo}/`,
								query:{id:eventId},
								})

	const [ Pdata, Ploading, Perror ] = usePost( detailFetchData )

	useEffect( () =>{
		if( Perror !== undefined && Perror !== null && Perror.detail === undefined ){
			const ids = Object.keys( Perror )
			for ( let id of ids ){
				showError(id, Perror[id] )
			}
		}
	}, [ Pdata, Ploading, Perror ] )

	return( <>
			<EventDetailModal
				listValues={Pdata}
				showHandler={() => {
						showHandler('hide')
						}
				}
				title='Prenotazione effettuata'
				body="E' stata inviata un email di conferma al cliente"
				btnText='Ok'
				btnAction={ () => {
						showHandler('hide') 
				}}
			>
			</EventDetailModal>
		</> ) 
}




export default NewBookingModalContainer
