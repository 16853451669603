import { useState } from 'react'

import './CheckBox.css'



const CheckBox = ({ checked, text, handler }) =>{

	const [ isChecked, setIsChecked ] = useState( checked )

	return (
		<div 
			className='checkbox'
			onClick={ () => handler() }
		>
			<label 
				htmlFor='#check-acconto'>
				{
				text
				}	
			</label>
			<input 
				id='check-acconto'
				type='checkbox'
				checked={ checked}
			/>
		</div>
	)
}



export default CheckBox
