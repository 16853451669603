import { Link } from 'react-router-dom'
import { useRef, useState, useCallback, useContext, useEffect } from 'react'
import './MenuItemDropdown.css'
import { usePost } from '../Hooks'
import { BASE_URL } from  '../Constants'
import { UserContext } from '../context/UserContext'


import { HiOutlineClipboardList } from 'react-icons/hi'
import { MdOutlineAccountCircle } from 'react-icons/md'


const logoutUrl = new URL(`${ BASE_URL() }/signout/`)

const MenuItemDropdown = ({ closeMenu, classes, labelText, subMenuList, to}) =>{

	const dropDownContainerRef = useRef()
	const [fetchValues, setFetchValues] = useState({})
	const [ data, loading, error ] = usePost( fetchValues )

	const [, setUser ] = useContext( UserContext )



	const renderIcon = useCallback( ()=>{

		if( labelText === 'Report' )
			return <HiOutlineClipboardList style={{'fontSize':'1.5rem'}}/>
		else
			return <MdOutlineAccountCircle style={{'fontSize':'1.5rem'}}/>



	},[ labelText ] )

	const toggleDropdown = useCallback(( ref ) => {
		const dropdown = ref.current
		const parentDropdown = dropdown.parentNode

		const itemHeight = 65 
		const dropDowns = parentDropdown.querySelectorAll('.dropdown-menu') 

		if (dropdown.classList.contains('dropdown-active')){
			dropdown.classList.remove('dropdown-active')
			dropdown.style.height = '0px'
			dropdown.classList.add('dropdown-deactive')
		} else {
			for ( let item of dropDowns){
				item.classList.remove('dropdown-active')
				item.style.height = '0px'
				item.classList.add('dropdown-deactive')
			}
			const nchild = dropdown.children.length
			dropdown.classList.remove('dropdown-deactive')
			dropdown.classList.add('dropdown-active')
			dropdown.style.height = `${nchild * itemHeight}px`
		}
	}, [] )

	return(

		<>
			<li 
				className='menu-item' 
				onClick={ ()=>
					toggleDropdown( dropDownContainerRef ) 
				}
			>

				<div className='menu-text'>
				{
					renderIcon()
				}
					<p>
						{ labelText }	
					</p>

				</div>

                        </li>
					<ul 
						className={ `dropdown-menu dropdown-deactive` }
						ref={ dropDownContainerRef }
					>
						{
						subMenuList.map( ( element, index ) =>(
							<li 
								key={index}
							>
								<Link 
									to={to[index]}
									onClick={ () => {
									closeMenu( )
									toggleDropdown(dropDownContainerRef)
									if ( element ==='logout'){
										setFetchValues( { url:logoutUrl, method:'get' })
										setTimeout( () =>{
											setUser({})
											sessionStorage.removeItem('user')
										},500 )
									}
									if ( element === 'pannello admin' ){
										const protocol = window.location.protocol
										const port = window.location.port
										window.open(`${protocol}//${window.location.hostname}:${port}/PlrVh0EmzNzfs4JIO0Bpf6N6EuNFgo1e2rk2aw/`,"_blank"); 
									}

									}}
								>
									{element}
								</Link>
							</li>
							))
						}
                                        </ul>

		</>
	)
}



export default MenuItemDropdown
