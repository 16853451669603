import { BASE_URL } from './Constants'



class FetchError extends Error{
	constructor( message, data ){
		super(message)
		this.message = message
		this.data = data
	}
}


export const getWeekDay = ( day ) =>{

	switch(day){
		case 0:
			return 'Dom'
		case 1:
			return 'Lun'
		case 2:
			return 'Mar'
		case 3:
			return 'Mer'
		case 4:
			return 'Gio'
		case 5:
			return 'Ven'
		case 6:
			return 'Sab'
		default:
			return 'Unknown'
	}
}



export const getMonthName = ( month ) =>{

	switch( month ){
		case 0:
			return 'Gennaio'
		case 1:
			return 'Febbraio'
		case 2:
			return 'Marzo'
		case 3:
			return 'Aprile'
		case 4:
			return 'Maggio'
		case 5:
			return 'Giugno'
		case 6:
			return 'Luglio'
		case 7:
			return 'Agosto'
		case 8:
			return 'Settembre'
		case 9:
			return 'Ottobre'
		case 10:
			return 'Novembre'
		case 11:
			return 'Dicembre'
		default:
			return 'Undefined'
	}
}



function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)===' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}


export const checkNoUser = () => {
	if ( 
		sessionStorage.user === undefined ||
		sessionStorage.user === "{}" ||
		sessionStorage.user === 'undefined')
	{
		return true
	}
	else
	{
		return false
	}
}






export const checkUndefArr = (arr) => {
	for ( let ele of arr ){
		if (ele === undefined || ele === null)
			return false
	}
	return true
}



export const fixTypesQuery = (strValues) =>{
	let dictValues= JSON.parse(strValues)
	for ( let key of Object.keys(dictValues) ){
		const element = dictValues[key]
		if ( /^\d+$/.test(element) ){
			dictValues[key] = parseInt(element)
		}

	}
	return dictValues
}



export const formatHint = (hint) =>{
	if ( typeof hint == 'number' )
		return hint

	if( hint === null || ! hint.length > 0  ){
		return
	}
	const first = hint[0].toUpperCase()
	const body =  hint.replaceAll('_',' ').slice(1, hint.length)
	return	`${first}${body}` 
}


export const refreshToken = async ( key ) =>{

	const refreshUrl = BASE_URL()+'/token/refresh/'

	const contentData = {
		method:'POST',
		headers:{
			'Content-Type':'application/json',
			'Accept':'application/json',
		},
		body: JSON.stringify({
			'refresh':key
		})
	}


	return await fetch(refreshUrl, contentData)
		.then( resp => {return resp.json()}
		)
		.catch( error => { return error } )
}




const clearErrors = async () =>{
	const old_errors = document.querySelectorAll( '.error-label' )
	for ( let label of old_errors ){
		label.remove()
	}
}

const findCorrectId = ( id ) =>{

	if ( id === 'nome_festeggiati' )
		return '#nome_festeggiati_1'
	else if ( id === 'eta_festeggiati' )
		return '#eta_festeggiati_1'
	else 
		return `#${id}`


}

export const showError = (id, content) =>{
	clearErrors()
		.then( _ =>{
				const target = document.querySelector(
					/*
					id === 'nome_festeggiati'?
					`#numero_festeggiati`:
					`#${id}`
					*/
					findCorrectId( id )

				)
				if ( target !== null ){
					const trgParent = target.parentNode
					const label = document.createElement('p')
					label.innerHTML =  content 
					label.classList.add('error-label')
					trgParent.append(label)
				}
			})
}



export const isDictUndef = ( dict ) =>{
	if( dict === undefined ){
		return true
	}
	const keys = Object.keys(dict)

	if (keys.length > 0){
		return false
	}
	else {
		return true
	}
}



export const fetchPost = async ( url, query, key ) =>{

	let headersData ={
			 'Content-Type':'application/json',
			 'Accept':'application/json',
		 }


	 if ( key !== null ){
		 headersData['Authorization'] =`Bearer ${key}`
	 }

	const csrf_token = getCookie('csrftoken')

	if ( csrf_token !== null ){
		headersData['X-CSRFToken'] = csrf_token
	}

	return await fetch( url, {
		 method:'POST',
		 headers: headersData,
		 body: JSON.stringify(query)
	 	 })
		 .then( resp =>{
			 if (resp.ok){
				return resp.json()
			 }
			 else{
				const code = resp.status
		  	 	return resp.json().then(errors => {
					return {'error':errors, 'code':code}
			 		})
			 }
		 })
		.then( resp_data => {
			if (resp_data.error !== undefined){
				 if ( query.username )
			 		throw new FetchError('erro during login', resp_data)
				 else
					throw new FetchError('error fetching data', resp_data)
			}
			else return resp_data
		})

	
}




export const fetchGet = async ( url, key ) =>{
	let headersData ={
			 'Content-Type':'application/json',
			 'Accept':'application/json',
		 }

	 if ( key != null ){
		 headersData['Authorization'] =`Bearer ${key}`
	 }

	 return await fetch( url, {
		 method:'GET',
		 headers: headersData,
	 })
		 .then( resp =>{
			 if (resp.ok){
				return resp.json()
			 }
			 else return resp.json().then(errors => {
				 return {
				 	'error':errors
				 }
			 }) 
			
		 })
		.then( resp_data => {
			if (resp_data.error !== undefined){
				throw new FetchError('error fetching data',resp_data)
			}
			else return resp_data
		})
}





export const animateLabel = ( action,  id ) =>{

	const label = document.querySelector(`label[for=${id}]`)
	switch(action){


		case 'used':
			
			if ( id.includes('nome_festeggiato'))
				label.style.top = '-15px'
			else
				label.style.top = '-25px'
			label.style.left = '0'
			label.style.fontSize = '.7rem'
			label.style.color = 'var(--light-blue)'
			label.style.fontWeight = 'bolder'
			label.style.opacity = '1'
			break

		case 'unused':
			label.style.top = '0px'
			label.style.left = '10px'
			label.style.fontWeight = 'normal'
			label.style.fontSize = '.9rem'
			label.style.color = 'var(--black)'
			label.style.opacity = '.7'
			
			break

		default:
			
			break

	}
}
