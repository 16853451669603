import './CalendarEvent.css'
import { MdOutlinePaid } from "react-icons/md";
import { BsFillTelephoneFill } from 'react-icons/bs'
import { FaChild, FaGlassCheers } from 'react-icons/fa'
import { useMemo } from 'react'

import { FaBirthdayCake } from 'react-icons/fa'
import { GiWoodenChair } from 'react-icons/gi'

const CalendarEvent = ({  
	cognome,
	ora,
	event_id,
	tipo,
	showModal,
	paid,
	da_chiamare,
	animazione,
	tipo_compleanno,
	evento,
}) =>{






	

	const setClasses = useMemo( () =>{

		
		let base = 'calendar-event'
		if (evento.tipo === 'birthday'){

			base += ` orange`

			if ( evento?.nome_tipo_compleanno?.toLowerCase()?.includes('pranzo') ){
				base += ` pranzo`
			}
			if ( evento?.nome_tipo_compleanno?.toLowerCase()?.includes('happy bowling pn') ){
				base +=` happy-pn`
			}
			if ( evento?.nome_tipo_compleanno?.toLowerCase()?.includes('happy bowling') ){
				base += ` purple`
			}

		}
		else if (evento.tipo === 'evento')
			base = 'calendar-event blue'

		return base

	} , [
		evento.tipo,
		evento.tipo_compleanno
	])



	return(

		<div
			className={ setClasses }
			onClick={ () => showModal() }
		>

			<div className='calendar-event-info'>

			<p>{  `${event_id}` }</p>
			<p className='calendar-event-time'>{  `${ora}` }</p>

			{
				paid && 
					<MdOutlinePaid style={{
						'fontSize':'1rem',
						'height':'1rem',
						'width':'1rem'

			}}/>
			}
			{
				da_chiamare === true && 
					<BsFillTelephoneFill style={{
						'fontSize':'1rem',
						'height':'1rem',
						'width':'1rem'

					}}/>
			}

			{
				animazione === true &&
					<FaChild
						style={{ 
							'fontSize':'1rem',
							'height':'1rem',
							'width':'1rem'
						}}
					/>
			}
			{
				evento?.dolce === true &&
					<FaBirthdayCake
						style={{ 
							'fontSize':'1rem',
							'height':'1rem',
							'width':'1rem'
						}}
					/>
			}


			</div>





			<div
				style={{ 

					'display':'flex',
					'gap':'13px',
					'alignItems':'center',
					'justifyContent':'space-between',
				}}
			>
			<p>{  `${cognome}` }</p>

				<div style={{
					'whiteSpace':'nowrap',

					'display':'flex',
					}}>

			<div>
			<FaGlassCheers/>{ evento.numero_aperitivi ?? 0 }
			</div>
			<div>
			<GiWoodenChair/>{ evento.numero_invitati}
			</div>

			</div>

				</div>



			{

				evento.nome_tipo_compleanno &&
					<p className='tipo-compleanno'>{  `${evento.nome_tipo_compleanno}` }</p>
			}


			
		</div>
	)
}


export  default CalendarEvent
