import { useState, useEffect, useMemo, useCallback } from 'react'
import './TableRow.css'
import Cella from './Cella'
import { formatHint } from '../Methods'


import { NOT_PRINT_FIELD } from '../Constants'

const TableRow = ({  columns, header, isTot })=>{

	const [ classes, setClasses ] = useState('')

	useEffect( () =>{
		if ( header === true )
			setClasses('table-row table-header')

		else if ( isTot === true ){

			setClasses('table-row print-only')
		}
		else if ( 
			columns.acconto_pagato !== undefined &&
			columns.acconto_pagato === true ){
				setClasses( 'table-row pagato' )	
		} 
		else if ( 
			columns.acconto_pagato !== undefined &&
			columns.acconto_pagato === false ){
				setClasses( 'table-row non-pagato' )	
		} 
		else
			setClasses('table-row')

	},[columns.acconto_pagato, header] )



	

	const setPrintClass = useCallback( ( key ) =>{

		if ( NOT_PRINT_FIELD.includes( key ) ){
			return true
		}
		else{
			return false
		}

	}, [] )




	const renderCelle = useMemo( () =>{
		return(
			Object.keys(columns).map( ( key, index) =>{
				

				if( 
					key === 'is_active' ||
					key === 'da_chiamare'

				)
					return null


				else if  ( header === true  ){
					return(
						<Cella
							content={formatHint(key) }
							key={ index }
							notPrint={ setPrintClass( key ) }
						/>
					)
				}

				else if ( columns[key] !== null && typeof columns[key] === "object" ){
					const names = Object.values(columns[key])
					return(
						<Cella 
							key={ index }
							notPrint={ setPrintClass( key ) }
						>
							{names.map( (nome, index) =>{
								return(
									<p>{nome}</p> 
								)
							})}
						</Cella>
					)
				}
				else if ( columns[key] !== null && typeof columns[key] === "boolean" ){

					if (columns[key] === true)
						return(
							<Cella
								content='si' 
								key={ index }
								notPrint={ setPrintClass( key ) }
							/>
						)
					else if ( columns[key] === false )
						return(
							<Cella
								content='no' 
								key={ index }
								notPrint={ setPrintClass( key ) }
							/>
						)



				}
				return(
					<Cella
						content={formatHint(columns[key]) }
						key={ index }
						notPrint={ setPrintClass( key ) }
					/>
				)
			})
		)
	},  [ columns, header ])


	
	return(

			<tr className={ classes }>
			{
				renderCelle
			}

			</tr>
	)
}



export default TableRow
