import {  useCallback, useContext } from 'react'
import Modal from './Modal'
import { isDictUndef, formatHint } from '../Methods'
import './EventDetailModal.css'
import {FaTimes} from 'react-icons/fa'


import ModalActionButtons from './ModalActionButtons'
import {UserContext} from '../context/UserContext'



const EventDetailModal = ({  
	listValues,
	showHandler,
	children,
	title,
	body,
	btnText,
	btn1Text,
	btn2Text,
	btn3Text,
	btnAction,
	showDeleteHandler,
	showAccontoHandler,
	toggleDaChiamare,

}) =>{


	const [ user, ] = useContext(UserContext)

	const formatInfo = useCallback( ( value ) =>{
		switch(value){
			case true:
				return 'Si'
			case false:
				return 'No'
			case null:
				return ''
			default:
				return value
		}
	}, [])


	const flatListValues = useCallback( () =>{
		let flatDict = {}
		if( listValues.booking )
			flatDict = { ...flatDict, ...listValues.booking }
		if( listValues.evento)
			flatDict = { ...flatDict, ...listValues.evento }
		if( listValues.cake )
			flatDict = { ...flatDict, ...listValues.cake }
		if( listValues.parents)
			flatDict = { ...flatDict, ...listValues.parents }
		return flatDict
	} , [ listValues ])

	const renderDetails = useCallback( () =>{
		if( listValues !== null && !isDictUndef(listValues) ){
			const dataList = flatListValues()
			return (
			Object.keys( dataList ).map( (key, index) =>{

				if ( key === 'date' || key === 'booking' ) return null
				if ( key === 'nome_festeggiati' || key === 'eta_festeggiati' ){
					return(
						<li key={index}>
							<label>
								{`${formatHint(key)}: `}
							</label>
							<div>
							{
							Object.values( dataList[key] ).map( (nome, index) =>{
								return(
									<span key={index}>
									{ 
										index +1 !==  Object.keys( dataList[key]).length ?
											nome+' - ':
											nome
									}
									</span>
								)
							} )
							}
							</div>
						</li>
					)
				}
				return(
					<li key={index}>
						<label>
							{`${formatHint(key)}:`}
						</label>
						<span>{` ${ formatInfo(dataList[key])}`}</span>
					</li>
				)
			}))
		}
	}, [ 
		flatListValues,
		formatInfo,
		listValues,
	] )


	return(
		<>
			{
				children
			}
		<Modal>
			<div 
				className='modal-container'
				onClick={ (event)=> {
					if(event.target !== event.currentTarget){
  						return
					}	
						showHandler('hide')
					}}
			>

				<section 
					className='event-detail'
					onClick={ () => null }
				>
					<div 
						className='modal-close-icon'
						onClick={ () =>  showHandler('hide') }
					>
						<FaTimes></FaTimes>
					</div>
				{
					title !== undefined?
					<h3>{title}</h3>:
					null
				}
				{
					body !== undefined ?
						<p>{body}<br/><br/></p>
							:
					null
				}
					<ul className='detail-modal-list'>
					{
						renderDetails()
					}
					</ul>


				{
					user.solo_lettura === false &&

				<ModalActionButtons
					btnAction={ () => btnAction() }
					btnText={ btnText }
					btn1Text={ btn1Text  }
					btn1Callback={ () =>  showAccontoHandler( true ) }
					btn2Text={ btn2Text  }
					btn2Callback={ () =>  showDeleteHandler( true )	}
					btn3Text={ btn3Text  }
					btn3Callback={ () =>  toggleDaChiamare() }
				
				/>

				}

				</section>
			</div>	
		</Modal>
		</>
	)
}


export default EventDetailModal
