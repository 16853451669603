import { useState, useEffect, useCallback } from 'react'
import TextInput from '../views/TextInput'
import Button from '../views/Button'
import CheckBox from '../views/CheckBox'
import './DateFilterSection.css'
import { formatHint } from '../Methods'
import { useForm, useGet, usePost } from '../Hooks'
import { BASE_URL } from '../Constants'
import Loader from '../sections/Loader'
import * as htmlToImage from 'html-to-image';
import EmailPasticceriModal from '../sections/EmailPasticceriModal'



const getPasticcereEmailsUrl = new URL(  BASE_URL()+'/get_email_pasticceri/')
const sendEmailUrl =  new URL(BASE_URL()+'/send_file/')


const DateFilterSection = ({  filterCallback, cake, tableRef, currentCake }) =>{


	const [ soloAcconto, setSoloAcconto ] = useState( false )
	const [ getData, ] = useState( { url:getPasticcereEmailsUrl } )
	const [ postData, setPostData ] = useState( { url:null, query:null } )
	const [ pasticcere, setPasticcere ] = useState({})
	const [ showEmailModal, setShowEmailModal ] = useState(false)

	const [
		formValues,
		setFormValues,
		setFormValuesByEvent
	] = useForm( {
		start_data:'',
		end_data:''
	})

	const [ Gdata, Gloading, Gerror ] = useGet( getData )
	const [ Pdata, Ploading, Perror ] = usePost( postData )



	const showModalHandler = useCallback( ( show ) =>{
		show ?
			setShowEmailModal(true):
			setShowEmailModal(false)
	}, [ setShowEmailModal ] )



	useEffect( () => {
		if ( Pdata != null){
			setPostData({ url:null, query:null})
		}
	}, [ Pdata])


		
	const sendEmail = useCallback( () =>{
		tableRef.current.classList.add( 'table-print' )
		htmlToImage.toPng( tableRef.current )
			.then(dataUrl => {
				//setPostData({ url:null, query:null })
				setPostData( { url:sendEmailUrl, query:{ image:dataUrl, torte:currentCake } } )
			});
		setTimeout( () =>{
			tableRef.current.classList.remove( 'table-print' )
		}, 1000 )

	}, [tableRef, setPostData, currentCake] )



	useEffect( () =>{
		if( Gdata ){
			if (Gdata.pasticcere){
				setPasticcere( Gdata.pasticcere )
			}
		}

	}, [ Gdata, Gloading, Gerror, setPasticcere ] )

	return(
		<div className='data-filter-container'>

			{
				Ploading &&
					<Loader/>
			}

			{
				showEmailModal &&
					<EmailPasticceriModal
						showHandler={ ( show ) => showModalHandler(show) }
						pasticcere={ pasticcere }
						sendEmail={ () => sendEmail() }
					/>
			}


			<div className='date-row'>
				<TextInput
					type='date'
					text={ formValues.start_data }
					onchange={ (event) => setFormValuesByEvent( event ) }
					name='start_data'
					hint={ formatHint('data inizio') }
				/>

				<TextInput
					type='date'
					name='end_data'
					onchange={ (event) => setFormValuesByEvent( event ) }
					text={ formValues.end_data }
					hint={ formatHint('data fine') }
				/>

				<CheckBox
					checked={soloAcconto}
					text='Solo acconto pagato'
					handler={ () => setSoloAcconto( old => !old ) }
				/>
			</div>


			<div className='date-row'>
			<Button 
				text='Filtra'
				onclick={ () => {
					filterCallback({
								start_data:formValues.start_data,
								end_data:formValues.end_data,
								solo_acconto:soloAcconto
							})
				
				} }
			/>


			</div>

			<div className='date-row'>

			{
				cake &&
					<Button 
						text='Invia a pasticcere'
						color='green'
						onclick={ () => {
							setShowEmailModal(true)
						}
							
						}
					/>

			}
			<Button 
				color='green'
				text='Stampa'
				onclick={ () => { window.print() } }
			/>

			</div>
		</div>
	)
}


export default DateFilterSection
