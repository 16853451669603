



import './Divider.css'



const Divider = ({ title }) =>{


	return (
		<div className='divider'>
			<h4 className='title'>{title}</h4>
			<div className='line'/>
		</div>
	)

}

export default Divider
