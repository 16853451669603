import { useState, useEffect, useRef, useCallback  } from 'react'
import {  Redirect } from 'react-router-dom'
import { usePost } from '../Hooks'
import { BASE_WS } from '../Constants'
import './OverviewSection.css'
import Table from '../views/Table'
import DateFilterSection from '../sections/DateFilterSection'
import { isDictUndef, checkNoUser } from '../Methods'
import Loader from './Loader'
import AuthCheck from '../views/AuthCheck'


const OverviewSection = ({ url, cake=false }) => {


	const tableRef = useRef()
	const filtersRef = useRef()
	const [ PdataState, setDataState ] = useState([])
	const [ fetchPostData, setFetchPostData ] = useState({url:null, query:null})

	const [ 
		Pdata,
		Ploading,
		Perror
	] = usePost( fetchPostData )


	useEffect( () =>{
		if ( Pdata !== null && !isDictUndef(Pdata) && Pdata.length > 0 ){
			setDataState(Pdata)
			setFetchPostData({ url:null, query:null})
		}
		else{
			setDataState([])
		}
	},  [Pdata] )


	useEffect( () => {

		if ( Pdata != null){

			setFetchPostData({ url:null, query:null })
		}
	}, [ Pdata])

	const filterCallback = useCallback( params => {

		setFetchPostData({ url:url, query:params})
	}, [ setFetchPostData, url] )

	useEffect( () =>{

		const ws = new WebSocket(`${ BASE_WS() }/update_events/`)
		ws.onmessage = ( msg ) =>{

			if ( msg.data !== null){ 
				const data =  JSON.parse(msg.data) 
				if ( !isDictUndef( data ) ){
					Object.keys( data ).forEach( ( key ) =>{
						if (key === 'action'){
							if (data.action === 'reload'){
								setFetchPostData( old  =>{ 
									return {...old, query:null }
								
								})
								setFetchPostData( old  => {
										return { 
											...old,
											query:{all:true}
											}
								})
							}
						}
					} )
				}
			}
		}
		ws.onerror = function(e) {}
		return () =>{
			ws.close()
		}
	},[setDataState] )	




	

	useEffect( () =>{
		setFetchPostData( oldData => {
			return {
				url:url,
				query:{all:true}
			}
		})
	},[] )



	return(
		<>
			<AuthCheck/>

			{
				Ploading &&
					<Loader/>
			}


			<DateFilterSection 
				cake={ cake }
				tableRef={ tableRef }
				currentCake={ PdataState }
				filterCallback={ params => filterCallback( params )}
			/>

			<Table 
				ref={ tableRef } 
				bookings={ PdataState }
			/>


		</>
	)


}




export default OverviewSection
