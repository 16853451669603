import { useEffect, useState, useContext, useCallback} from 'react'
import { Redirect } from 'react-router-dom'
import './LoginContainer.css'
import { BASE_URL } from '../Constants'
import TextInput from '../views/TextInput'
import Button from '../views/Button'
import Card from '../views/Card'
import { useForm, usePost } from '../Hooks'
import { UserContext } from '../context/UserContext'
import { isDictUndef } from '../Methods'
import Loader from './Loader'


const loginUrl = new URL( `${BASE_URL()}/signin/`)
const getUserInfoUrl = new URL(`${BASE_URL()}/get_info/`)

const initialFetchValues = {
	url:'',
	method:'',
	query:''
}

const initialLoginValues = {
	'username':'',
	'password':'',
}






const LoginContainer = () =>{

	const [ fetchData, setFetchData ] = useState( initialFetchValues )
	const [ errorMessage, setErrorMessage ] = useState(null)
	const [ redirect, setRedirect ] = useState(false)

	const [ user, setUser ] = useContext( UserContext )


	const [
		loginValues,
		setLoginValues,
		setLoginValuesByEvent
	] = useForm(initialLoginValues)


	const [Pdata, Ploading, Perror] = usePost(fetchData)

	const sendLogin = useCallback(  () => {

		setFetchData({})
		setFetchData({ 'url':loginUrl, 'query':loginValues })
		setLoginValues( initialLoginValues )
	}, [ setLoginValues, initialLoginValues, setFetchData, loginValues ])

	useEffect( () =>{

		if ( Perror !== null && !isDictUndef( Perror )){
			setErrorMessage( Perror )
		}
		else if ( Pdata !== null){
			
			if ( Pdata.access ){
				setUser( Pdata )
				setFetchData({url:getUserInfoUrl, query:{'info':'get_user_info'}})
			}

			else if( Pdata.name ){
				let newuser = JSON.parse( sessionStorage.user )
				newuser = {
					...newuser,
					name:Pdata.name,
					is_admin:Pdata.is_admin,
					solo_lettura:Pdata.solo_lettura,
				}
				setUser( newuser )
				setRedirect(true)
			}
		}
	},[ Pdata, Perror, setUser ] )


	if (redirect === true)
	{
		return 	<Redirect to='/'/>
	}

	return(

		<Card  margin='auto'>

			{
				Ploading &&
					<Loader/>
			}

			<div 
				className='login-container'
				onKeyUp={ (event) =>{
					if ( event.keyCode === 13 ){
						sendLogin()
					}
				}}
			>

				{
					errorMessage !== null &&
						!(Object.keys( errorMessage ).includes('username') ||
						Object.keys( errorMessage ).includes('password')) ?
						Object.keys(errorMessage).map( ( key, index ) =>{
							return (
								<p 
									className='error-message'
									key={index}
								>
								{errorMessage[ key ]}
								</p> 
							)

						} ):
					null
				}


				<TextInput 
					text={ loginValues.username }
					onchange={ event => setLoginValuesByEvent(event) }
					hint={ 'username' }
					name='username'
					error={ errorMessage && errorMessage.username }

				/>

				<TextInput 
					text={ loginValues.password}
					onchange={ event => setLoginValuesByEvent(event) }
					hint={ 'password' }
					name='password'
					type='password'
					error={ errorMessage && errorMessage.password}

				/>

				<Button  
					onclick={ () => {
						sendLogin()
						}
					}
					text='Entra'
					color='primary'
				/>
			</div>
		</Card>
	)
}


export default LoginContainer
