

import './Card.css'



const Card = ({ children, margin, padding }) => {



	return(
		<div className='card' style={{'margin':margin, 'padding':padding}}>
		{
			children
		}
		</div>
	)


}


export default Card
