import { useLayoutEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { UserContext } from '../context/UserContext'


const AuthCheck = ()=>{
	const history = useHistory()
	const [ user, setUser ] = useContext( UserContext )

	useLayoutEffect( () =>{
		!sessionStorage.user && history.push('/login')

		!user.access && history.push('/login')
	} )
	return (<></>)
}
export default AuthCheck
