

import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io'

import './ArrowButton.css'



const ArrowButton = ( { onclick, left, right  } ) =>{


	const renderIcon = () =>{
		if (left){
			return (
				<IoIosArrowBack/>
			) 
		}
		else if( right ){
			return(
				<IoIosArrowForward/>
			)
		}
	}



	return(
		<div 
			className='arrow-button'
			onClick={() => onclick()}
		>
			{
			renderIcon()
			}
		</div>
	)
}



export default ArrowButton
