import React, { useState, useEffect } from 'react'
import './Table.css'
import TableRow from './TableRow'
import { isDictUndef } from '../Methods'
import { NOT_PRINT_FIELD } from '../Constants'





const Table = React.forwardRef( ({ bookings }, ref) => {

	const [ headers, setHeaders ] = useState([])
	
	useEffect( () => {
		if (  bookings !== null && !isDictUndef(bookings) ){
			const row = Object.values(bookings)[0]
			const head = Object.keys( row )
			const dict = head.filter( item => !NOT_PRINT_FIELD.includes(item) )
			setHeaders( dict )
		}

	},  [bookings] )


	const renderBookings = () =>{
		if (bookings === null){
			return
		}


		let totInvitati = 0
		let totAperitivi = 0

		let currDate = null
		let currDay = null
		return Object.values(bookings).map( (element, index) => {



				if ( currDay != null && currDay !== element.data.split('/')[0] ){

					const totRow = {}
					// 	{
					// 	...element,
					// 	aperitivo_genitori:totAperitivi,
					// 	numero_invitati:totInvitati,
					// }
					Object.keys( element ).forEach(key => {

						if ( key === 'aperitivo_genitori'){
							totRow[ key ] = totAperitivi
						}
						else if ( key === 'numero_invitati'){
							totRow[ key ] = totInvitati
						}
						else if ( key === 'id'){
							totRow[ key ] = 'Totali giornata' 
						}
						else if ( key === 'data'){
							totRow[ key ] = currDate
						}
						else{
							totRow[ key ] = ''
						}
					})

					totAperitivi = element.aperitivo_genitori !== false ? parseInt(element.aperitivo_genitori) : 0
					totInvitati = parseInt(element.numero_invitati)
					currDay = element.data.split('/')[0]
					currDate = element.data

					return [
						<TableRow 
							columns={ totRow }
							key={`${index}_${element.id}_${Math.random()*1000}`}
							isTot={ true }
						/>,
						<TableRow 
							columns={element}
							key={`${index}_${element.id}_${Math.random()*1000}`}
						/>,
					]
				}
				else if ( index + 1 === Object.values( bookings ).length) {


					totAperitivi += element.aperitivo_genitori !== false ? parseInt(element.aperitivo_genitori) : 0
					totInvitati += parseInt(element.numero_invitati)
					const totRow = {}
					Object.keys( element ).forEach(key => {
						if ( key === 'aperitivo_genitori'){
							totRow[ key ] = totAperitivi
						}
						else if ( key === 'numero_invitati'){
							totRow[ key ] = totInvitati
						}
						else if ( key === 'id'){
							totRow[ key ] = 'Totali giornata' 
						}
						else if ( key === 'data'){
							totRow[ key ] = currDate
						}
						else{
							totRow[ key ] = ''
						}
					})


					return [
						<TableRow 
							columns={element}
							key={`${index}_${element.id}_${Math.random()*1000}`}
						/>,
						<TableRow 
							columns={ totRow }
							key={`${index}_${element.id}_${Math.random()*1000}`}
							isTot={ true }
						/>,
					]

				}
				else{
					currDay = element.data.split('/')[0]
					currDate = element.data
				totAperitivi += element.aperitivo_genitori !== false ? parseInt(element.aperitivo_genitori) : 0
				totInvitati += parseInt(element.numero_invitati)
					return 	<TableRow 
							columns={element}
							key={`${index}_${element.id}_${Math.random()*1000}`}
						/>
				}
				
			} )
	}


	return(
		<table 
			ref={ ref }
			className='table-container'>

			<thead>
			<TableRow 
				columns={ Object.values( bookings )[0] ?? {} }
				header={true}
			/>
			</thead>
			<tbody>
			{
				renderBookings().flat()
			}
			</tbody>
		</table>
	)
})



export default Table
