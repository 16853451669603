import React, { useEffect, useCallback, useRef, useLayoutEffect  } from 'react'
import { animateLabel } from '../Methods'

import './TextInput.css'





const activeLabel = (event) =>{
	const id = event.target.id
	if (
		!id.includes('data') &&
		!id.includes('ora')
	){
		if (event.target.value.trim().length === 0){
			animateLabel( 'unused' ,id)
		}
		else if ( event.target.value.trim().length > 0 ){

			animateLabel( 'used' ,id)
		}
	}
}




const TextInput = React.forwardRef(  ( { text='', name, hint, onchange, type, digit, error }, ref ) =>{


	useLayoutEffect( () =>{
		if( text !== null && text.toString().length > 0){
			animateLabel('used',name)
		}
		if( 
			name === 'ora' ||
			name === 'data' ||
			name === 'start_data'||
			name === 'end_data'
		){
			animateLabel('used',name)
		}
	})


	return(
		<div className='text-input-container'>

			<label 
				className={'input-label'
				}
				htmlFor={name}
			>
			{ hint }
			</label>
			
			<input
				className='text-input'
				type={ 
					type !== null ?
					type:
					'text'
				}
				value={ text }
				onChange={ event =>{
					activeLabel(event)
					if (digit ===  true ){
						event.target.value =
							event.target.value.replaceAll( 
								/[^0-9:]/ig, '' 
							)
					}
					
					if ( onchange !== null ){
						onchange(event)
					}
				}}

				maxLength={ 
					name === 'numero_festeggiati' ? 1 : -1 
				}

				onBlur={ event =>{
					activeLabel(event)
				}}
				onFocus={ event =>{
					if ( 
						!name.includes('data') || 
						!name.includes('ora') 
					) animateLabel( 'used' ,event.target.id)
				}}
				onKeyUp={ event => {
					if ( event.keyCode === 13 )
						event.target.blur()
				}}
				name={name}
				id={name}
				ref={ ref }
			/>

			{
				error && 
					<p className='error-label'>
						{ error }
					</p>
			}
		</div>
	
	)
})


export default TextInput
