import './TextArea.css'



const TextArea = ({ name, text, onchange, hint }) => {

	return(

		<div className='textarea-container'>

			<label
				className='label-fix'
				htmlFor={ name }
			>
			{hint}
			</label>

			<textarea
				maxLength='499'

				name={name}
				id={name}
				className='textarea'
				onChange={ event => {
					if (onchange !== undefined)
						onchange(event)
				}
				}
				value={ text === null ? '' : text }
			>

			</textarea>

		</div>
	)
}


export default TextArea
